import {Pane, TileLayer} from "react-leaflet";
import React from "react";
// tile layer with only labels
// possible sources of tile servers can be previewed at
// https://leaflet-extras.github.io/leaflet-providers/preview/
// example from leaflet documentation
// https://leafletjs.com/examples/map-panes/

/**
 * Tiles with geographical namings
 * @function OverlayLabels
 * @kind React component
 */
const OverlayLabels = ()=> {
    return (<Pane name={"custom-ovelay"}
                  style={{"zIndex": 600}}>
        <TileLayer
            url='https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png'
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
    </Pane>)
};

export default OverlayLabels;