import React from 'react';
import PropTypes from "prop-types";
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Avatar from '@material-ui/core/Avatar';
import {Grid} from "@material-ui/core";
import logo from "../data/s-pro-mara-logo-banner EU Nor.png";
import logo_lab_est from "../data/logo_est_sinine.png";
import logo_lab_eng from "../data/logo_eng_sinine.png";
import logo_tartu_est from "../data/logo_tartu_est.png";
import logo_tartu_eng from "../data/logo_tartu_eng.png";
import {strings} from "../localization/strings";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    root: {
        padding:20,
        margin: 10,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Information about the application
 * @function InfoDialog
 * @kind React component
 * @param {Object} props - props
 * @param [props.locale] {("en"|"et")} - localization
 * @param [props.style] {Object} - style object
 */
export default function InfoDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={props.style}>
            <Button
                variant="outlined"
                color="default" onClick={handleClickOpen}>
                {strings.info}
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                scroll={'body'}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>

                        <Typography variant="h6" className={classes.title}>
                            {strings.usageAndInformation}
                        </Typography>
                        <Avatar alt={"PMM"} src={"logo192.png"}/>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                    <img src={logo} alt={"Logo"}
                         style={{
                             width: "100%",
                         }}
                    />
                    {props.locale==="en"?
                    <React.Fragment>
                        <Typography>
                        <b>The Population Mobility Monitor (PMM)</b> is an experimental data management and visualisation tool for showing realised mobility 
                        flows based on digital data sources. The tool enables to understand the spatio-temporal dynamics of human mobility and thus supports 
                        public authorities, transport service providers and other interested parties in their decision-making. The tool is developed by  
                        the <a href={"https://mobilitylab.ut.ee/en/about/"} target={"_blank"} rel="noopener noreferrer">Mobility Lab</a> at the University of Tartu.
                        <br/><br/>
                        As a proof-of-concept, the PMM uniquely integrates a range of digital mobility data sources to comparable spatial and temporal units. 
                        The anonymous data are aggregated to origin-destination flows at the level of neighbourhoods in urban settings or territorial units 
                        in sparsely populated areas. The temporal accuracy of the data ranges from hourly flows to monthly statistics. The users can download 
                        the aggregated data sets for further analysis. The PMM is an open-source development to enable transparency, further development and 
                        replication. The code is accessible from the repository of the Mobility Lab at <a href={"http://gitlab.ut.ee/public-pmm"} target={"_blank"} rel="noopener noreferrer">gitlab.ut.ee/public-pmm</a>. 
                        <br/><br/>
                        The current version of the PMM presents mobility flows in Tartu City and Tartu County, Estonia. The data are available for fixed periods 
                        without real-time updates. The tool covers the following mobility data sets: <br/>
                        <b>a) Daily regular movements in Tartu county based on passive mobile positioning data</b> - the original data set includes call detail 
                        records data from one major mobile network operator in Estonia. The population flows are extrapolated to the whole population covering 
                        January 2017 until March 2018. Aggregated origin-destination flow data belongs to <a href={"https://doi.org/10.23659/UTMOBLAB-1"} target={"_blank"} rel="noopener noreferrer">The Mobility Lab of University of Tartu</a>.<br/>
                        
                        <b>b) Tartu county bus ticketing data</b> - PMM covers the start and end stops of the bus trips made in Tartu county during 2017-2019. The 
                        data is based on the Ridango ticketing system and originate from the Estonian Transport Administration. <br/>

                        <b>c) Tartu city public transport data</b> - since July 2019 the city of Tartu has a new improved bus network. PMM utilises the bus ticketing
                         data from 2019 until the end of the year. Access to data was provided by Tartu City Government. <br/>

                        <b>d) Tartu city bike share data</b> - in June 2019 Tartu city launched a bike share system with 69 parking stations over the city. 
                        The information about the starting and ending points of the rides are saved. PMM uses the first six months of bike-share data. 
                        Access to data was provided by Tartu City Government.
                        <br/>
                        <br/>
                        The PMM is developed in the frame of the project MARA – Mobility and Accessibility in Rural Areas. MARA is funded by the Interreg Baltic Sea 
                        Region programme of the European Union and lasts from January 2019 to September 2021. The project aims to improve the accessibility and 
                        mobility in remote touristic areas of the Baltic Sea Region by increasing the capacity of transport actors and offering mobility solutions 
                        that meet the mobility needs of residents and tourists. More information about the project can be found at <a href={"http://www.mara-mobility.eu"} arget={"_blank"} rel="noopener noreferrer">www.mara-mobility.eu</a>.
                        <br/>
                        For feedback and questions contact <a href={"mailto:mobilitylab@ut.ee"}>mobilitylab@ut.ee</a>.
                        </Typography>
                        <Grid container align = "center"  justify = "center">
                                <img src={logo_lab_eng} alt={"Logo_laboratory"}
                                    style={{
                                        height: 130,
                                    }}
                                />
                                <img src={logo_tartu_eng} alt={"Logo_Tartu"}
                                    style={{
                                        height: 93,
                                        marginTop: 20,
                                    }}
                                />
                                </Grid>
                    </React.Fragment>
                    : props.locale==="et"?
                            <React.Fragment>
                                <Typography>
                                <b>Rahvastiku liikuvusseire portaal</b> on eksperimentaalne liikumisandmete haldamise ja visualiseerimise keskkond, mis näitab 
                                inimeste liikumise dünaamikat digitaalsete liikumisandmete põhjal. Liikuvusseire portaal aitab ruumi- ja transpordiplaneerijatel
                                 ning teistel huvilistel saada lihtsasti ülevaade inimeste tegelikest liikumisvoogudest. Avatud lähtekoodiga keskkond on 
                                 valminud <a href={"https://mobilitylab.ut.ee/et/meist/"} target={"_blank"} rel="noopener noreferrer">Tartu Ülikooli mobiilsusuuringute labori</a> eestvedamisel. 
                                <br/>
                                <br/>
                                Liikuvusseire portaal pakub esimese omalaadse rakendusena erinevate anonüümsete andmestike koondamise ja kuvamise võimalust 
                                võrreldavate ruumi- ja ajaühikute kaupa. Andmed koondatakse alates ühe tunni täpsusest kuni kuupõhise statistikani. Ruumiliselt 
                                eristatakse inimeste liikumist kas linnaliste asumite või kantide täpsusega. Portaali koondatud agregeeritud liikumisandmeid ja 
                                ruumiüksuste kihte on võimalik alla laadida edasiseks täpsemaks analüüsiks. Arendus on avatud lähtekoodiga, mis on kättesaadav 
                                mobiilsusuuringute labori andmehoidlast aadressil <a href={"http://gitlab.ut.ee/public-pmm"} target={"_blank"} rel="noopener noreferrer">gitlab.ut.ee/public-pmm</a>. 
                                <br/>
                                <br/>
                                Praegune eksperimentaalne arendus keskendub Tartu linnas ja maakonnas kogutud digitaalsetele liikumisandmetele. Andmed kuvatakse 
                                piiratud perioodi kohta ning automaatset andmete uuenemist eksperimentaalses versioonis ei toimu. Portaalis kuvatakse järgmiseid 
                                digitaalseid liikumisandmeid:
                                <br/>
                                <b>a) Tartumaa pendelränne passiivse mobiilpositsioneerimise andmetel</b> – liikuvusseire portaal näitab Tartu maakonna sisest 
                                pendelrändeandmestikku vahemikus 01.01.2017–01.03.2018. Andmestik tugineb Eesti ühe suurema mobiilioperaatori andmetele, mille 
                                põhjal on leitud kogu rahvastikku hõlmavad liikuvusnäitajad. Agregeeritud andmete allikas: Tartu Ülikooli mobiilsusuuringute labor.
                                <br/>
                                <b>b) Tartu maakonnaliinide andmed</b> – portaalist leiab Tartu maakonna bussiliinide sõitude andmestiku alates 2017. aasta algusest 
                                kuni 2019. aasta lõpuni. Maakonnaliinid kasutavad Ridango piletisüsteemi ja andmed pärinevad Transpordiametilt.
                                <br/>
                                <b>c) Tartu linna ühistranspordi andmed</b> – 2019. aasta juulist alates on Tartu linnal uus korrastatud bussiliinivõrk. Liikuvusseire 
                                portaal kasutab uue liinivõrgu kuue kuu valideerimisandmeid alatest 1. juulist 2019. Andmete allikas: Tartu Linnavalitsus.
                                <br/>
                                <b>d) Tartu linna rattaringluse andmed</b> – 2019. aasta juunis käivitus Tartus ülelinnaline rattaringluse süsteem, mille raames saab 69 
                                rattaparklast üle linna laenutada rendiratast. Tehtud sõitude andmed (algus- ja lõpp-punkt, aeg) salvestatakse. Liikuvusseire portaal 
                                kasutab rattaringluse kuue kuu andmeid alates 1. juulist 2019. Andmete allikas: Tartu Linnavalitsus.
                                <br/>
                                <br/>
                                Rahvastiku liikuvusseire portaal loodi Euroopa Liidu Läänemere programmi rahastatava projekti „Mobiilsus ja ligipääsetavus maapiirkonnas.
                                 Äärealade mobiilsuskontseptsioonide uued käsitlused – MARA“ raames. 2019. a jaanuarist 2021. a septembrini kestva projekti eesmärk on parendada 
                                 Läänemere-äärsete turismipiirkondade ligipääsetavust ja transpordiühendusi. Rohkem informatsiooni projekti kohta leiab 
                                 aadressilt <a href={"http://www.mara-mobility.eu"} target={"_blank"} rel="noopener noreferrer"> www.mara-mobility.eu</a>.
                                 <br/>
                                 Tagasiside ja küsimuste korral kirjutada <a href={"mailto:mobilitylab@ut.ee"}>mobilitylab@ut.ee</a>.
                                </Typography>
                                <Grid container align = "center"  justify = "center">
                                <img src={logo_lab_est} alt={"Logo_laboratory"}
                                    style={{
                                        height: 130,
                                    }}
                                />
                                <img src={logo_tartu_est} alt={"Logo_Tartu"}
                                    style={{
                                        height: 90,
                                        marginTop: 20,
                                    }}
                                />
                                </Grid>
                            </React.Fragment>
                            :
                            null
                    }



                </div>
            </Dialog>
        </div>
    );
}

InfoDialog.propTypes = {
    style: PropTypes.object,
    locale: PropTypes.string,
}