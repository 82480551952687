/**
 * Rounds value x to given precision
 * @param {number} x - value to be rounded
 * @param {number} precision - precision value
 *
 * @returns {number}
 */
function roundToPrecision(x, precision) {
    var y = +x + (precision === undefined ? 0.5 : precision/2);
    return y - (y % (precision === undefined ? 1 : +precision));
}

/**
 * Generates n bins with the center bin includes the median value
 * @param {number} quantile - proposed quantile value
 * @param {number} n - amount of bins
 * @param {number} precision - precision
 * @returns {number[]}
 */
function countBins(quantile, n, precision) {
    return ([...Array(n).keys()].map(
        (key) => roundToPrecision(key*2*quantile/n, precision)
    ));
}

/**
 * Counts 0.9 quantile based on array of values
 * @param {Array<number>} values - values for which bins are calculated
 *
 * @returns {Array<number>}
 */
function countQuantile(values) {
    values.sort((a,b)=>a-b);
    return values[Math.floor(values.length * 0.9)];
}

/**
 * Calculates a color for each numeric value, calculate bins if not provided
 * @param {Object} table - {id: value} map with values corresponding to region id
 * @param {Array<string>} colorMap - array of colors corresponding to bins
 * @param {Array<number>} bins -  array of 5 values
 * @returns {Array} - colorsTable object {id: color} and bins
 */
export function calculateColors(table, colorMap, bins) {
    // table {id: value}
    if (bins===undefined){
        const median = countQuantile(Object.values(table));
        const precision = [1,5,50,500,5000,50000,500000][Math.floor(Math.log10(countQuantile(Object.values(table))))];
        bins = countBins(median, 5, precision);
    }

    // const bins = [0, 10, 20, 30, 40];

    const toColor = (value) => {
        let i=4;
        while (bins[i]+1>value) {
            i--;
        }
        return colorMap[i + (value<1&&value>0 ? 1:0)];
    };

    const colorsTable = {};
    Object.keys(table).forEach(
        (key) => {
            colorsTable[key] = toColor(table[key]);
        }
    );
    return [colorsTable, bins];
}

/**
 * Calculates a color for each numeric value for a difference case
 * @param {Object} table - {id: value} map with values corresponding to region id
 * @param {Array<string>} colorMap - array of colors corresponding to bins
 * @param {Array<number>} bins -  array of 5 values
 * @returns {Array} - colorsTable object {id: color} and bins
 */
export function calculateColorsDif(table, colorMap, bins) {

    const toColor = (value) => {
        if (value<bins[0]) {
            return colorMap[0]
        } else if (value < bins[1]) {
            return colorMap[1]
        } else if (value > bins[4]) {
            return colorMap[4]
        } else if (value > bins[3]) {
            return colorMap[3]
        } else {
            return colorMap[2];
        }
    }
    const colorsTable = {};
    Object.keys(table).forEach(
        (key) => {
            colorsTable[key] = toColor(table[key]);
        }
    );
    return [colorsTable, bins];
}