import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        // width:"100%",
        // height:"100%",
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
        // justifyContent:"center",
        // alignItems:"center",
        // zIndex: 520
    },
}));

export default function LoadingWheel(props) {
    const classes = useStyles();

    return (
        <Fade in={props.show}
              unmountOnExit
        >
            <div className={classes.root}>
                <CircularProgress />
            </div>
        </Fade>

    );
}