import { tartumaaCentroids, tartuCentroids, tartumaaNames, tartuNames } from "../Estonia/regionValues";
import { aggregateCountByFieldsArrayAsync } from "../utils/aggregationUtils";

/**
 * Generates props for an ArcGroup component
 * @async
 * @param {?(string | number)}      id          - id of a region in the data table
 * @param {Array}                data        - JSON-like OD table or array of tables
 * @param {boolean}              arrayFlag   - whether data is an array of tables (true) or just a one table (false)
 * @param {string}               dataType    - name of a geographical region
 * @param {number}               value       - value of a slider of Controller component
 * @param {("hour"| "day" |"none")}filterMode  - ("hour", "day", "none") data resolution for filtering by value
 * @returns {Object}
 */
export async function prepareArcGroup(id, data, arrayFlag, dataType, value, filterMode) {
    // if (!(dataType in ["tartu", "tartumaa"] && !filterMode in ["hour", "day", "none"]) {
    if (!["tartu", "tartumaa"].includes(dataType) && !["hour", "day", "none"].includes(filterMode)) {
        console.log("undefined arc")
        return undefined;
    } else {
        // console.log(data);
        const startID = dataType === "tartu" ? "start_neighborhood_id" : "start_tc_code";
        const endID = dataType === "tartu" ? "end_neighborhood_id" : "end_tc_code";
        const centroids = dataType === "tartu" ? tartuCentroids : tartumaaCentroids;
        const names = dataType === "tartu" ? tartuNames : tartumaaNames;

        // filter if the filterMode is hour
        const filter = (val)=>parseInt(val[startID])===parseInt(id) || parseInt(val[endID])===parseInt(id);
        let filteredData = arrayFlag?data.map(v=>v.filter(filter)) : data.filter(filter);
        if (filterMode==="hour") {
            const hourFilter = (val) => parseInt(val["hour"].slice(11,13))===value;
            filteredData = arrayFlag?filteredData.map(v=>v.filter(hourFilter)) : filteredData.filter(hourFilter);
        }


        const agg2 = await aggregateCountByFieldsArrayAsync(
            filteredData,
            arrayFlag,
            [startID, endID],
            1,
            false,
        );

        // [{start: z, end: y, count: b}]
        // {regionId: [inCount, outCount]}
        const res = await aggregateArcTableAsync(agg2, id, startID, endID);
        const startCentroid = centroids[id];
        const startName = names[id];
        const targetCentroids = await Object.keys(res).map((key) => [centroids[key], ...res[key]]);
        const targetNames = await Object.keys(res).map((key) => names[key]);
        return ({
            startCentroid,
            startName,
            targetCentroids,
            targetNames,
        });
    }
}

async function aggregateArcTableAsync(agg, id, startID, endID) {
    return new Promise( function(resolve){
        const aggWorker = new Worker("workers/arcWorker.js");
        aggWorker.onmessage = function (e) {
            // console.log("received message");
            resolve(e.data);
        }
        aggWorker.postMessage([agg, id, startID, endID]);
    })
}