import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { strings } from "../localization/strings";


export default function DataSelectorController(props) {
    return(
        <React.Fragment>
            <FormControlLabel
                control ={<Switch
                    size="small"
                    name="od"
                    checked={props.state.od}
                    onChange={props.handleSwitchChange}
                    color="primary"
                />}
                label={strings.mobilePositioningDataInTartuCounty}
                disabled={props.disabled}
            /><br/>
            <FormControlLabel
                control ={<Switch
                    size="small"
                    name="countyBus"
                    checked={props.state.countyBus}
                    onChange={props.handleSwitchChange}
                    color="primary"
                />}
                label={strings.tartuCountyBusData}
                disabled={props.disabled}
            /><br/>
            <FormControlLabel
                control ={<Switch
                    size="small"
                    name="bus"
                    checked={props.state.bus}
                    onChange={props.handleSwitchChange}
                    color="primary"
                />}
                label={strings.tartuCityBusData}
                disabled={props.disabled}
            /><br/>
            <FormControlLabel
                control ={<Switch
                    size="small"
                    name="bike"
                    checked={props.state.bike}
                    onChange={props.handleSwitchChange}
                    color="primary"
                />}
                label={strings.tartuCityBikeShareData}
                disabled={props.disabled}
            /><br/>

        </React.Fragment>
    );
}

DataSelectorController.propTypes = {
    disabled: PropTypes.bool,
    state: PropTypes.exact({
        od: PropTypes.bool,
        countyBus: PropTypes.bool,
        bike: PropTypes.bool,
        bus: PropTypes.bool,
    }),
    handleSwitchChange: PropTypes.func,
};

DataSelectorController.defaultProps = {
    state: {
        od: false,
        countyBus: false,
        bike: false,
        bus: false,
    },
    handleSwitchChange: ()=>{},
}

