import React from "react";
import PropTypes from "prop-types";
import {strings} from "../localization/strings";
import hash from "object-hash";


/**
 * Component to show a legend for corresponding width of ArcGroup component
 * @function SmoothLineLegend
 * @kind React component
 * @param {Object} props
 * @param [props.colors] {Array<string>}
 * @param [props.text] {string}
 * @param [props.bins] {Array<number>}
 */
export function SmoothLineLegend(props) {
    const w = 63;
    const h = 1.5;
    const k = props.bins.length-1;
    return (
        <div
            className={"any-text"}
            key={hash(props.bins)}
            style={{
                width:(w)*(k)+h*k*0.5+0.5*h,
                bottom: "calc(2% - 6px)",
                right: "33%",
                background: "rgba(255,255,255,0.7)",
                position: "fixed",
                zIndex: 510,
                padding:10,
                borderRadius:20,
                ...props.style}}
        >
            <svg width={(w)*(k)+h*k*0.5+0.5*h} height={h*k+25}>
                <path d={`M${h/2} ${h*k}  a${h},${h} 0 0 1 0 -${h}  L ${w+h/2} ${h*(k-1)} L ${k*w+h/2} ${0}  a${h*k/2},${h*k/2} 0 0 1 0 ${h*k} Z`} fill={"#bababa"} key={1}/>
                {props.bins.slice(1).map((b, i) => <path d={`M ${(i)*w+h/2+w} ${h*(k-i-1)}  L ${(i)*w+h/2+w} ${h*k+25}`} stroke={"#000000"} key={i+1}/> )}
            </svg>
            <br/>
            {props.bins.slice(1).map((val, i) => <div
                style={{position:"relative", width:w, float:"left", background:'#eaeaea', borderRadius:5}}
                key={`${i}_`}>
                <span style={{float:"right"}}>{
                    i===props.bins.length-2?`≥${val}` :
                        i===0? `≤${val}` :
                            val
                }</span>
            </div>)}
            <br/>
            <div style={{background: props.colors[0],
                width: 18,
                height: 18,
                float: "left",
                marginRight: 2,
                opacity: 0.9}}/>
            {strings.outgoingMobility}
            <br/>
            <div style={{background: props.colors[1],
                width: 18,
                height: 18,
                float: "left",
                marginRight: 2,
                opacity: 0.9}}/>
            {strings.incomingMobility}
            <br/>
        </div>
    );
}






SmoothLineLegend.propTypes = {
    colors: PropTypes.arrayOf(PropTypes.string),
    text: PropTypes.string,
    // binsToWidth: PropTypes.func,
    bins: PropTypes.arrayOf(PropTypes.number),
}
SmoothLineLegend.defaultProps = {
    colors: ["#ffa366", "#6691ff"],
    // binsToWidth: binsToWidth,
    bins: [0, 5, 10, 50, 500, 5000, 50000, 100000,],
}
