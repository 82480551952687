import React, { Component } from "react";
import {
  Route,
  HashRouter,
} from "react-router-dom";
import { Redirect } from "react-router-dom";
import MainMap from "./mainMap/MainMap";

/**
 * Main root component, routing is done here
 * @function Main
 * @kind React component
 */
class Main extends Component {
  render() {
      return (
          <HashRouter>
              <div>
                  <div className="content">
                      <Route exact path="/"
                      >
                          <Redirect to="/et" />
                      </Route>
                      <Route path="/et" render={()=><MainMap locale={"et"}/>}/>
                      <Route path="/en" render={()=><MainMap locale={"en"}/>}/>
                  </div>
              </div>
          </HashRouter>
      );
  }
}
 
export default Main;

