
/**
 * Aggregates JSON-like data table by a set of fields summing values of "count" up
 * @async
 * @param {Array}    data - JSON-like table or array of tables
 * @param {boolean}  arrayFlag - whether data is an array of JSON-like tables
 * @param {array}    fields - array of names of data fields (columns of table)
 * @param {number}   divider - a value by which the aggregate sum has to be divided
 * @param {boolean}  round - whether to round an aggregated value
 *
 * @returns {Promise<Array>}  JSON-like table with fields and aggregated (summed and divided) "count" value
 */
export async function aggregateCountByFieldsArrayAsync(data, arrayFlag, fields, divider, round) {
    // {fieldName: [value, value]}
    return new Promise(function(resolve) {
            const aggWorker = new Worker("workers/aggregationCountByFieldsWorker.js");
            aggWorker.onmessage = function (e) {
                // console.log("received message");
                resolve(e.data);
            }
            aggWorker.postMessage([data, arrayFlag, fields, divider, round])
        }
    )
}

/**
 * Aggregates JSON-like data table by a field summing values of "count" up
 * @param {Array}   data - JSON-like table or array of tables
 * @param {boolean} arrayFlag - whether data is an array of JSON-like tables
 * @param {string}  field - field by which data is aggregated
 * @param {number}  divider - a value by which the aggregate sum has to be divided
 * @param {boolean} round - whether to round an aggregated value
 *
 * @returns {Promise<object>} {fieldValue: countValue}
 */
export async function aggregateByFieldAsync(data, arrayFlag, field, divider, round) {
    return new Promise( function(resolve){
        const aggWorker = new Worker("workers/aggregationCountByFieldsWorker.js");
        aggWorker.onmessage = function (e) {
            // console.log("received message");
            resolve(e.data);
        }
        const asMap = true;
        aggWorker.postMessage([data, arrayFlag, [field,], divider, round, asMap]);
    })
}