import React, {useState, useEffect, useRef} from 'react';

import { MapContainer, Pane, useMap, ZoomControl } from 'react-leaflet';
import ViewPortUpdate from './ViewPortUpdate'

import LayerControl from "./LayerControl";
import Tartu from "../Estonia/Tartu";
import Tartumaa from "../Estonia/Tartumaa";
import Controller from "../controller/Controller";
import OverlayLabels from "./OverlayLabels";
import InfoDialog from "../dialog/InfoDialog";
import LegendMovers from "./LegendMovers";
import { strings } from "../localization/strings";

import {
    intersectDateBoundaries,
    maxDate,
    minDate, normalizeInterval,
    toDay,
    urlMaxDayHour,
    urlMinDayHour
} from "../utils/dateStringUtils";
import {token} from "../token";
import BarDiagram from "../barDiagram/BarDiagram";
import {prepareDataBarDiagramAsync,
    prepareDataBarDiagramStackedAsync} from "../barDiagram/barDiagramUtil"
import ArcGroup from "../arc/ArcGroup";
import { prepareArcGroup } from "../arc/arcGroupUtil";

import MaraLogo from "./MaraLogo";
import DownloadDialog from "../dialog/DownloadDialog";
import {useInterval} from "../hooks/useInterval";
import {prepareColorScheme} from "../Estonia/colorSchemeUtil";
import LocalizationPanel from "./LocalizationPanel";
import {useWinHeight} from "../hooks/useWinSize";
import hash from "object-hash";
import {SmoothLineLegend} from "./SmoothLineLegend";
import LoadingWheel from "./LoadingWheel";
// import { set } from 'date-fns';


/**
 * Main UI and data fetching component
 * @function MainMap
 * @kind React component
 * @param {Object} props
 * @param [props.locale] {("en"|"et")} - set language locale
 */
export default function MainMap(props) {
    // window height to update elements position
    const winHeight = useWinHeight();

    // map settings
    const [viewPort, setViewPort] = useState({zoom: 9, center: { lat: 58.3757193, lng: 26.7279221 }});
    const tartuViewport = {zoom: 12, center: { lat: 58.3757193, lng: 26.7279221 }};
    const tartumaaViewport = {zoom:10, center: { lat: 58.3757193, lng: 26.7279221 }};

    // localization settings
    const locale = props.locale
    strings.setLanguage(locale);

    // data selection
    const [dataState, setDataState] = useState({
        od: false,
        countyBus: false,
        bike: false,
        bus: false,
    });
    
    // effect for the default data to be displayed
    // fires after the page have been loaded
    // triggers data fetching
    useEffect(()=>{
        handleDataSwitchChange({target: {name:"countyBus", checked:true}});
    }, [])

    //data storage
    // data JSON-like array Array<Object>
    // date Array<Date>
    // type {("hour"|"day")} temporal scale of data
    const dataStorage = useRef({
        od: {data:[], date:[undefined, undefined], type:"none"},
        countyBus: {data:[], date:[undefined, undefined], type:"none"},
        bike: {data:[], date:[undefined, undefined], type:"none"},
        bus: {data:[], date:[undefined, undefined], type:"none"},
        // preparedArcData: [],
    });


    // flag shortcuts whether to show polygons for a territorial unit
    // used into return div
    const showTartu = (dataState.bus || dataState.bike);
    const showTartumaa = (dataState.od || dataState.countyBus);

    // id's of the selected regions
    const [tartuID, setTartuID] = useState(null);
    const [tartumaaID, setTartumaaID] = useState(null);

    // date selection
    const [selectedDate, setSelectedDate] = useState([new Date(), new Date()]);
    const [dayMonthRangeState, setDayMonthRangeState] = useState({
        enableYear: true,
        enableMonth: false,
        enableDay: false,
    });

    // hardcoded boundaries
    const dateBoundaries = {
        od: [new Date("2017-01-01T00:00:00"), new Date("2018-03-01T23:00:00") ],
        countyBus: [new Date("2017-01-01T00:00:00"), new Date("2019-12-31T23:00:00")],
        bus: [new Date("2019-07-01T00:00:00"), new Date("2019-12-31T23:00:00")],
        bike: [new Date("2019-07-01T00:00:00"), new Date("2019-12-31T23:00:00")]
    };
    const [currentDateBoundaries, setCurrentDateBoundaries] = useState(Object.entries(dateBoundaries)
        .filter((a)=>dataState[a[0]])
        .map((a)=>a[1])
        .reduce(intersectDateBoundaries, [undefined, undefined]));

    // helper function
    // finds the intersection of date boundaries and updates them
    function updateCurrentDateBoundaries(currentDataState) {
        setCurrentDateBoundaries(Object.entries(dateBoundaries)
            .filter((a)=>currentDataState[a[0]])
            .map((a)=>a[1])
            .reduce(intersectDateBoundaries, [undefined, undefined]))
        }

    // slider control
    const [sliderValue, setSliderValue] = useState(7);
    const [animate, setAnimate] = useState(false);

    // loading control to display loading progress animation
    const [loading, setLoading] = useState(false);
    const [preparing, setPreparing] = useState(false);
    const [error, setError] = useState(false);
    const abortController = new AbortController();
    const signal = abortController.signal;

    // colorScheme for regions
    const [colorScheme, setColorScheme] = useState(undefined);
    const [colors, setColors ] = useState(['#ffffcc','#a1dab4','#41b6c4','#2c7fb8','#253494'])
    const [bins, setBins] = useState([0, 5, 10, 100, 1000]);
    // bins for smooth line legend
    const [smoothLineBins, setSmoothLineBins] = useState([0, 5, 10, 50, 500, 5000, 50000, 100000]);
    // bins are harcoded empirically
    const updateBins = (dayMonthRangeState_, dataSate_, selectedArcs_) => {
        const state = [
            Object.entries(dataSate_).filter(v=>v[1]).map(v=>v[0]).join("$"),
            Object.entries(dayMonthRangeState_).filter(v=>v[1]).map(v=>v[0]).join("$")
        ].join("$");
        const binsPreset = {
            "od$enableYear": [0, 400, 750, 1150, 1500],

            "countyBus$enableYear": [0, 1000, 2500, 3500, 5000],
            "countyBus$enableMonth": [0, 40, 75, 115, 150],
            "countyBus$enableDay": [0, 5, 10, 15, 20],

            "bike$enableYear": [0, 1500, 3500, 5000, 6500],
            "bike$enableMonth": [0, 50, 150, 200, 300],
            "bike$enableDay": [0, 2, 4, 7, 8],//1-2 3-4 5-7 8->8

            "bus$enableYear": [0, 40000, 80000, 120000, 160000],
            "bus$enableMonth": [0, 1500, 2500, 4000, 5000],
            "bus$enableDay": [0, 100, 200, 300, 400],

            "bike$bus$enableYear": [0, 40000, 80000, 120000, 160000],
            "bike$bus$enableMonth": [0, 1500, 2500, 4000, 5000],
            "bike$bus$enableDay": [0, 100, 200, 300, 400],
        };
        const smoothBinsPreset = {
            "od$enableYear": [0, 200, 400, 550, 750, 900, 1150, 1500],

            "countyBus$enableYear": [0, 500, 1000, 1500, 2500, 3000, 3500, 5000],
            "countyBus$enableMonth": [0, 20, 40, 60, 75, 90, 115, 150],
            "countyBus$enableDay": [0, 2, 5, 7, 10, 12, 15, 20],

            "bike$enableYear": [0, 750, 1500, 2500, 3500, 4250, 5000, 6500],
            "bike$enableMonth": [0, 25, 50, 75, 150, 175, 200, 250, 300],
            "bike$enableDay": [0, 1, 2, 3, 4, 5, 6, 7],//1-2 3-4 5-7 8->8

            "bus$enableYear": [0, 20000, 40000, 60000, 80000, 100000, 120000, 160000],
            "bus$enableMonth": [0, 750, 1500, 2000, 2500, 3250, 4000, 5000],
            "bus$enableDay": [0, 50, 100, 150, 200, 250, 300, 400],

            "bike$bus$enableYear": [0, 20000, 40000, 60000, 80000, 100000, 120000, 160000],
            "bike$bus$enableMonth": [0, 750, 1500, 2000, 2500, 3250, 4000, 5000],
            "bike$bus$enableDay": [0, 50, 100, 150, 200, 250, 300, 400],
        };
        const diffBins = dayMonthRangeState_.enableDay?
            [-5, -1,0, 1, 5]
            :
            dayMonthRangeState_.enableMonth?
                [-15, -3, 0, 3, 15]
                :
                [-20, -5, 0, 5, 20];
        selectedArcs_.in && selectedArcs_.out ? setBins(diffBins) : setBins(binsPreset[state])
        setSmoothLineBins(smoothBinsPreset[state]);
    };

    const [regionValues, setRegionValues] = useState(undefined);

    // arcs' state:
    // arcState - object to be fed into the ArcGroup component 
    const [arcState, setArcState] = useState(undefined);
    // arc selection values
    const [selectedArcs, setSelectedArcs] = useState({
        in: false,
        out: true,
    });

    // helper flag meaning that there are no arcs selected 
    const noMobilityData = !(selectedArcs.in || selectedArcs.out);

    // object to be fed into the BarDiagram component
    const [barDiagramState, setBarDiagramState] = useState(undefined);

    // callback for data type switch
    // updates data type selection
    const handleDataSwitchChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;
        const newState = {...dataState, [name]: checked}

        if (checked){
            switch (name) {
                case "od":
                    newState["countyBus"] = false;
                    newState["bus"] = false;
                    newState["bike"] = false;
                    setTartuID(null);
                    break;
                case "countyBus":
                    newState["od"] = false;
                    newState["bus"] = false;
                    newState["bike"] = false;
                    setTartuID(null);
                    break;
                case "bus":
                    newState["od"] = false;
                    newState["countyBus"] = false;
                    setTartumaaID(null);
                    break;
                case "bike":
                    newState["od"] = false;
                    newState["countyBus"] = false;
                    setTartumaaID(null);
                    break;
                default:
                    break
            }
        }

        // flags whether to zoom to the territorial unit
        const tartumaaChanged = ((newState.od !== dataState.od ) !== (newState.countyBus !== dataState.countyBus))
            &&  !dataState.od && !dataState.countyBus;
        const tartuChanged = ((newState.bus !== dataState.bus ) !== (newState.bike !== dataState.bike))
            &&  !dataState.bus && !dataState.bike;

        if (tartumaaChanged) {
            setViewPort(tartumaaViewport);
        }
        if (tartuChanged) {
            setViewPort(tartuViewport);
        }

        // prevent day, month regime for od data
        const newDayMonthRangeState = {
            enableYear: true,
            enableMonth: false,
            enableDay: false
        }
        if (newState.od && (dayMonthRangeState.enableDay || dayMonthRangeState.enableMonth)) {
            setDayMonthRangeState(newDayMonthRangeState);
        }

        if (!Object.values(newState).reduce((a,b)=>a||b, false)){
            abortController.abort();
            setLoading(false);
            setTartumaaID(null);
            setTartuID(null);
        } else {
            const currentDateBoundaries = Object.entries(dateBoundaries)
                .filter((a)=>newState[a[0]])
                .map((a)=>a[1])
                .reduce(intersectDateBoundaries, [undefined, undefined]);

            if (dayMonthRangeState.enableDay) {
                setSelectedDate([new Date(currentDateBoundaries[1].getFullYear(), currentDateBoundaries[1].getMonth(), 1),
                    new Date(currentDateBoundaries[1].getFullYear(), currentDateBoundaries[1].getMonth(), 1)]);
            } else if(dayMonthRangeState.enableYear) {
                const monthInterval = newState.bike||newState.bus ? 5 : 11;
                setSelectedDate([
                    new Date(currentDateBoundaries[1].getFullYear(), currentDateBoundaries[1].getMonth()-monthInterval, 1),
                    new Date(currentDateBoundaries[1])
                ]);
            } else {
                setSelectedDate([new Date(currentDateBoundaries[1].getFullYear(), currentDateBoundaries[1].getMonth(), 1),
                    new Date(currentDateBoundaries[1].getFullYear(), currentDateBoundaries[1].getMonth() + 1, 0)]);
            }
        }
        setDataState(newState);
        updateBins(newDayMonthRangeState, newState, selectedArcs);
        updateCurrentDateBoundaries(newState);

    }

    // callback for the start and end date change
    // position stands for the value in the array of dates
    const handleDateChange = (position) => (date) => {
        if (position === 0) {
            if (isNaN(date.getTime())) {return}
            if (dayMonthRangeState.enableYear) {

                const newDate = normalizeInterval([new Date(date.getFullYear(), date.getMonth(), 1),
                    selectedDate[1]],
                    currentDateBoundaries,
                    "year");
                setSelectedDate(newDate);
            } else if (dayMonthRangeState.enableMonth) {

                const newDate = normalizeInterval([new Date(date.getFullYear(), date.getMonth(), date.getDate()),
                        selectedDate[1]],
                    currentDateBoundaries,
                    "month");
                setSelectedDate(newDate);
            } else if (dayMonthRangeState.enableDay) {
                setSelectedDate([date, new Date(date)]);
            }
        } else if (position === 1) {
            if (isNaN(date.getTime())) {return}

            if (dayMonthRangeState.enableYear) {
                if (dataState.od) {
                    // in case of OD round date to the first day of month
                    setSelectedDate(
                        normalizeInterval(
                            [selectedDate[0], new Date(date.getFullYear(), date.getMonth(), 1)],
                            currentDateBoundaries,
                            "year"
                        )
                    );

                } else {
                    // if not OD round date to the last day of the current month
                    setSelectedDate(
                        normalizeInterval(
                            [selectedDate[0], new Date(date.getFullYear(), date.getMonth() + 1, 0)],
                            currentDateBoundaries,
                            "year"
                        )
                    );

                }

            } else if (dayMonthRangeState.enableMonth) {
                const newDate = minDate(new Date(date.getFullYear(), date.getMonth(), date.getDate()+30), date);
                setSelectedDate(
                    [selectedDate[0],
                        minDate(newDate, currentDateBoundaries[1])
                    ]
                );
            }
        }
        setSliderValue(7);
    }

    // callback for left arrow button click
    // visible only in hourly case
    const handleLeftArrow = () => {
        const newDate = maxDate(
            new Date(selectedDate[0].getFullYear(), selectedDate[0].getMonth(), selectedDate[0].getDate()-1),
            currentDateBoundaries[0]
        );
        setSelectedDate([
            newDate, newDate
        ])
    }

    // callback for right arrow button click
    // visible only in hourly case
    const handleRightArrow = () => {
        const newDate = minDate(
            new Date(selectedDate[0].getFullYear(), selectedDate[0].getMonth(), selectedDate[0].getDate()+1),
            currentDateBoundaries[1]
        );
        setSelectedDate([
            newDate, newDate
        ])
    }

    // callback for date scale change: day(hourly), month(dayly), year(monthly) 
    const handleDateMonthRangeSwitchChange = (event) => {
        if (event.target.checked) {
            const newState = {...dayMonthRangeState, [event.target.name]: event.target.checked}
            Object.keys(dayMonthRangeState).forEach(key=>{
                if (key !== event.target.name) {
                    newState[key] = false;
                }
            });

            // logic to set the date values according to temporal scale regime
            const date = selectedDate[0];
            if (newState.enableYear) {
                setSelectedDate(
                    [new Date(date.getFullYear(), date.getMonth(), 1),
                        new Date(selectedDate[1].getFullYear(), selectedDate[1].getMonth()+1, 0)]
                );
            } else if (newState.enableMonth) {
                setSelectedDate(
                    [new Date(date.getFullYear(), date.getMonth(), 1),
                        new Date(date.getFullYear(), date.getMonth()+1, 0)]
                );
            } else if (newState.enableDay) {
                setSelectedDate([date, new Date(date)]);
            }
            setDayMonthRangeState(newState);
            updateBins(newState, dataState, selectedArcs);
        }

    }

    // callback for hour slider change
    const sliderHandleChange = (event, value) => {
        setSliderValue(value);
    }

    // callback for mobility type switch change
    const handleArcsSwitchChange = (event) => {
        const newSelectedArcs = {...selectedArcs, [event.target.name]: event.target.checked};
        setSelectedArcs(newSelectedArcs);
        updateBins(dayMonthRangeState, dataState, newSelectedArcs);
    }

    // callback for Clear Map button
    const handleClearMapClick = () => {
        setTartumaaID(null);
        setTartuID(null);
        setDataState({
            od: false,
            countyBus: false,
            bus: false,
            bike: false,
        });
        // setViewPort(tartumaaViewport);
        setDayMonthRangeState( {
            enableYear: true,
            enableMonth: false,
            enableDay: false,
        });
        setSelectedArcs({
            in: false,
            out: false,
        })
    }

    // callback for animation button
    const handleButtonAnimate = () => {
        if (!animate && sliderValue===23) {
            setSliderValue(0);
        }
        setAnimate(!animate);
    }

    // animation effect
    // used only for hourly data
    // increases sliderValue for one every second
    useInterval(()=>{
            if (sliderValue<23){
                setSliderValue(sliderValue+1);
            } else {
                setAnimate(false);
            }
        },animate?1000:null);

    // helper function to update BarDiagramState
    // according to dataState, dayMonthRangeState, dataStorage, currentDateBoundaries
    function updateBarDiagram() {
        return new Promise(resolve => {
            const enabled = Object.entries(dataState).reduce((arr, val) => {if (val[1]){arr.push(val[0])} return arr} ,[]);
            if (enabled.length > 0) {
                const showTartu = (dataState.bus || dataState.bike);
                const showTartumaa = (dataState.od || dataState.countyBus);
                const case_ = showTartu ? "tartu" : showTartumaa ? "tartumaa" : undefined;
                const mode_ = dayMonthRangeState.enableMonth ? "month" : dayMonthRangeState.enableDay ? "day" : "year";
                const id = showTartu ? tartuID : showTartumaa ? tartumaaID : undefined;
                const dataArray = Object.entries(dataState).filter(v => v[1]).map(v => v[0]).map(v => dataStorage.current[v].data);
                const interval = (dataState.bike || dataState.bus) && mode_==="year"?
                    [
                        new Date(new Date(currentDateBoundaries[1].getFullYear(), currentDateBoundaries[1].getMonth()-11, 1)),
                        currentDateBoundaries[1]
                    ]
                    :
                    undefined;
                if (dataArray.length<2) {
                    prepareDataBarDiagramAsync(
                        dataArray,
                        case_,
                        mode_,
                        id,
                        selectedDate,
                        selectedArcs.in,
                        selectedArcs.out,
                        interval
                    ).then((data) => {
                        setBarDiagramState({
                            data: data,
                            case: case_,
                            mode: mode_,
                            id: id,
                        });
                    });
                } else {
                    const labels = Object.entries(dataState)
                        .filter(v => v[1])
                        .map(v => v[0])
                        .map(v=>strings[v]);
                    prepareDataBarDiagramStackedAsync(
                        dataArray,
                        case_,
                        mode_,
                        id,
                        selectedDate,
                        selectedArcs.in,
                        selectedArcs.out,
                        interval,
                        labels
                    ).then((data)=>{
                        setBarDiagramState({
                            data: data,
                            case: case_,
                            mode: mode_,
                            id: id,
                            labels: labels,
                        });
                    });
                }
            }
            resolve("resolved");
        } )
    }

    // helper function to generate a URL of API GET request
    function prepareFetch(dataType, date, resolution) {
        // dataType {("od"|"countyBus"|"bus"|"bike")}
        // resolution {("day"|"hour")}
        // date {Array<Date>}
        const api = "https://mara.ut.ee/api/";
        const apiTables = {
            od: "od_matrix",
            countyBus: "county_results_agg_",
            bus: "city_results_agg_",
            bike:"bike_results_agg_",
        };
        const region = {
            od: "tc",
            countyBus: "tc",
            bus: "neighborhood",
            bike:"neighborhood",
        }
        const id = {
            od: "code",
            countyBus: "code",
            bus: "id",
            bike:"id",
        }
        const to_column = (res) => (dataType==="od"? "date" : {
            "hour": "hour",
            "day": "date",
            "month": "month"
        }[res])
        return api + apiTables[dataType] +
            (dataType==="od"?"":resolution) +
            "?" +
            `${to_column(resolution)}=gte.${toDay(date[0])}`+
            (resolution!=="hour"?"":urlMinDayHour)+
            `&${to_column(resolution)}=lte.${toDay(date[1])}`+
            (resolution!=="hour"?"":urlMaxDayHour)+
            (resolution==="month" && dataType!=="od"? 
                `&select=date:month,start_${region[dataType]}_${id[dataType]},end_${region[dataType]}_${id[dataType]},count`
                :
                `&select=${to_column(resolution)},start_${region[dataType]}_${id[dataType]},end_${region[dataType]}_${id[dataType]},count`);
            
    };

    // helper function to update color scheme
    async function updateColorScheme() {
        const showTartu = (dataState.bus || dataState.bike);
        const showTartumaa = (dataState.od || dataState.countyBus);
        const region = showTartu ? "tartu" : showTartumaa ? "tartumaa" : undefined;
        const temporalLevel = dayMonthRangeState.enableMonth ? "month" : dayMonthRangeState.enableDay ? "day" : "year";

        const divColors = ['#d01c8b','#f1b6da','#f7f7f7','#80cdc1', '#018571'];
        const baseColors = ['#ffffcc','#a1dab4','#41b6c4','#2c7fb8','#253494'];
        const diffOn = selectedArcs.in && selectedArcs.out;
        diffOn? setColors(divColors) : setColors(baseColors);
        let {table, newScheme, newBins}  = await prepareColorScheme(
             Object.entries(dataState).filter(v => v[1]).map(v => v[0]).map(v => dataStorage.current[v].data),
             region,
             temporalLevel,
             selectedDate,
             selectedArcs.in,
             selectedArcs.out,
             sliderValue,
             diffOn? divColors : baseColors,
             bins,
         );
        setRegionValues(table);
        setColorScheme(newScheme);
        return "resolved";
    }

    // helper function to update arcs
    function updateArcs () {
        setPreparing(true);
        return new Promise(resolve => {
        const showTartu = (dataState.bus || dataState.bike);
        const showTartumaa = (dataState.od || dataState.countyBus);
        const id = showTartu ? tartuID : showTartumaa ? tartumaaID : null;
        const dataType = showTartu ? "tartu" : "tartumaa";
        const filterMode = dayMonthRangeState.enableYear ? "none" : dayMonthRangeState.enableDay ? "hour" : "day";
        const data = Object.entries(dataState).filter(v=>v[1]).map(v=>v[0]).map(v=>dataStorage.current[v].data);
        const arrayFlag = true;
        if (id!==null) {
            prepareArcGroup(
                id,
                data,
                arrayFlag,
                dataType,
                sliderValue,
                filterMode
            ).then(
                (val)=>{setArcState(val)}
                );
        }
            resolve("resolved");
        });
    }

     // helper function to fetch and update data
     function fetchAPI(){
        const init = {headers: {'Authorization': 'Bearer ' + token, signal: signal}};
        const enabled = Object.entries(dataState).reduce((arr, val) => {if (val[1]){arr.push(val[0])} return arr} ,[]);
        const arrayWait = [];
        for (let dataItem of enabled) {
            const currentDate = dataStorage.current[dataItem].date;
            const newDate = selectedDate.map(toDay);
            const equalDates = currentDate.every((val, index) => val===newDate[index]); 
            // const newType = dayMonthRangeState.enableDay ? "hour" :  "day";
            const newType = dayMonthRangeState.enableDay ? "hour" : dayMonthRangeState.enableMonth? "day": "month";
            if (!equalDates || newType!==dataStorage.current[dataItem].type) {
                const apiRequest = prepareFetch(dataItem, selectedDate, newType);
                console.log(apiRequest);
                setLoading(true);
                setError(false);
                const response = fetch(apiRequest, init)
                    .then(res => res.json())
                    .then(res => {
                            if (res["message"]!==undefined){
                                console.error(res["message"]);
                                setLoading(false);
                                setError(true);
                            } else {
                                setLoading(false);
                                dataStorage.current[dataItem] = {data: res, date: newDate, type: newType};
                            }
                        },
                        (error) => {
                            console.log("error");
                            setLoading(false);
                            setError(true);
                        }
                    );
                arrayWait.push(response);
            } else {
                console.log("not fetched")
            }

        }
        return Promise.all(arrayWait);
    }


    // data fetching effect
    // makes data fetch and updates dependent states
    const [dateStart, dateEnd] = selectedDate;
    useEffect(() => {
        fetchAPI().then((res)=>{
            setPreparing(true);
            return Promise.all([
                updateArcs(),
                updateBarDiagram(),
                updateColorScheme(),
            ]);
        })
            .then((res) => {
                setPreparing(false);
                console.log("prepared");
            });

    }, [
        dateStart, dateEnd,
        selectedDate,
        sliderValue,
        dataState,
        dayMonthRangeState.enableDay, dayMonthRangeState.enableMonth,
        dataState.countyBus, dataState.od, dataState.bike, dataState.bus,
        tartuID,
        tartumaaID,
        selectedArcs.in,
        selectedArcs.out,
    ])

    // helper function to display 7:00-8:00 on LegendMOvers when Hourly mobility is chosen
    const hourText = () => {
        const splitSymbol = locale==="et"? "." : ":";
        return ` ${sliderValue}${splitSymbol}00-${(sliderValue+1)%24}${splitSymbol}00`;
    }

    return (
        <div >
            <MapContainer className="full-screen-map"
                 minZoom={7}
                 maxZoom={17}
                 maxBounds={[[60.6306917,21.450002],[57.4565789,29.412135]]}
                 zoom={viewPort.zoom}
                 center={viewPort.center}
                 zoomControl={false}
            >
                <ZoomControl position='topright'/>
                <ViewPortUpdate zoom={viewPort.zoom} center={viewPort.center} cityLevel={showTartu}/>
                <LayerControl/>
                <OverlayLabels/>

                <Pane style={{zIndex:510}}>
                {showTartu?<Tartu setID={(id)=>{setTartuID(id); console.log(id); }}
                                  colorScheme={colorScheme}
                                  regionValues={tartuID===null?regionValues:undefined}
                                  id={tartuID}
                />:null}

                {showTartumaa?<Tartumaa setID={(id)=>{setTartumaaID(id); console.log(id); }}
                                        colorScheme={colorScheme}
                                        regionValues={tartumaaID===null?regionValues:undefined}
                                        id={tartumaaID}
                />:null}
                </Pane>


                {!preparing?<Pane style={{zIndex:560}}>
                    {tartumaaID!==null && showTartumaa?
                        <ArcGroup {...arcState}
                                  showIn={selectedArcs.in}
                                  showOut={selectedArcs.out}
                                  popup={true}
                                  key={`${tartumaaID}_${sliderValue}_${selectedDate}`}
                                  id={tartumaaID}
                                  colors={["#ffa366", "#6691ff"]}
                                  bins={smoothLineBins}
                        />
                        :null}
                {tartuID!==null && showTartu?
                    <ArcGroup {...arcState}
                              showIn={selectedArcs.in}
                              showOut={selectedArcs.out}
                              popup={true}
                              key={`${tartuID}_${sliderValue}_${selectedDate}`}
                              id={tartuID}
                              colors={["#ffa366", "#6691ff"]}
                              bins={smoothLineBins}
                    />
                    :null}
                </Pane>:null}


                {(selectedArcs.out||selectedArcs.in)&&(tartuID!==null||tartumaaID!==null)?<SmoothLineLegend
                    key={hash(smoothLineBins|0)}
                    colors={["#ffa366", "#6691ff"]}
                    bins={smoothLineBins}
                    text={showTartumaa?strings.numberOfPeopleTravellingBetweenTerritorialCommunities
                        :
                        strings.numberOfPeopleTravellingBetweenCityNeighbourhoods
                    }
                />:null}
                {(showTartu||showTartumaa)&&(!noMobilityData)?<LegendMovers
                    colors={colors}
                    bins={bins}
                    diffMode={selectedArcs.out && selectedArcs.in}
                    text={
                        (selectedArcs.out && selectedArcs.in?
                                showTartu && !showTartumaa? strings.diffStartEndCity: strings.diffStartEndCounty :
                            dayMonthRangeState.enableMonth?
                            selectedArcs.in?
                                strings.dailyAverageNumberOfPeopleEndingTheirTrip
                                :
                                strings.dailyAverageNumberOfPeopleStartingTheirTrip
                            :
                            dayMonthRangeState.enableYear?
                                selectedArcs.in?
                                    strings.monthlyAverageNumberOfPeopleEndingTheirTrip
                                    :
                                    strings.monthlyAverageNumberOfPeopleStartingTheirTrip
                                :
                                selectedArcs.in?
                                    strings.numberOfPeopleEndingTheirTrip
                                    :
                                    strings.numberOfPeopleStartingTheirTrip
                        )
                        +
                        (dayMonthRangeState.enableDay? hourText() : "")
                    }
                    noInfo={selectedArcs.out && selectedArcs.in? undefined : "#fff"}
                    style={winHeight>800?{
                        bottom:"54%",
                        right: 10,
                    }:{
                        top:10,
                        right:"4%",
                    }}
                />:null}

                <MaraLogo/>
            </MapContainer>
            <Controller
                disabled={loading}
                noMobilityData={noMobilityData}

                dataState={dataState}
                handleDataSwitchChange={handleDataSwitchChange}

                dateVariant={dataState.od || dayMonthRangeState.enableYear?"year": dayMonthRangeState.enableMonth?"month":"day"}
                enableYear={dayMonthRangeState.enableYear}
                handleDateChange={handleDateChange}
                selectedDate={selectedDate}
                dateBoundaries={currentDateBoundaries}
                handleLeftArrow={handleLeftArrow}
                handleRightArrow={handleRightArrow}

                dayMonthRangeState={dayMonthRangeState}
                handleDateMonthRangeSwitchChange={handleDateMonthRangeSwitchChange}

                sliderValue={sliderValue}
                sliderHandleChange={sliderHandleChange}
                handleButtonAnimate={handleButtonAnimate}
                animate={animate}

                loading={loading}
                error={error}
                preparing={preparing}
                selectedArcs={selectedArcs}
                handleArcsSwitchChange={handleArcsSwitchChange}
                handleResetArcsClick={()=>{setTartuID(null); setTartumaaID(null);}}
                handleClearMapClick={handleClearMapClick}
            />
            <LoadingWheel show={loading||preparing}/>
            {(showTartu||showTartumaa) && (tartuID!==null || tartumaaID !== null) ?
            <div style={{position: "fixed",
                bottom: 14,
                right: 10,
                zIndex: 500,
                backgroundColor: "rgba(255,255,255,0.6)",
                height:"50%", width:"30%", borderRadius: 10,
                padding: 10,
            }}>
                <div className="recharts-legend-item-text"
                    style={{float: "left",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%) rotate(180deg)",
                    writingMode: "vertical-rl",
                    textAlign: "center",
                    width:"3%"}}>
                    {strings.numberOfTrips}
                </div>
                <div
                    style={{float: "left", position: "absolute", width: "97%", height: "99%"}}
                >
                    <BarDiagram
                        {...barDiagramState}
                        showIn={selectedArcs.in}
                        showOut={selectedArcs.out}
                    />
                </div>

            </div>:null}

            <div style={{
                position: "fixed",
                top:10,
                right:winHeight<800?"15%":"4%",
                background:"rgba(255,255,255,0.0)",
            }}>
                <LocalizationPanel
                    style={{
                        borderRadius:5,
                        zIndex:510,
                        background:"rgba(255,255,255,0.7)",
                        padding:3,
                        float: "left",
                        marginRight: 3,
                    }}/>
                <DownloadDialog
                    style={{
                        zIndex:510,
                        background:"rgba(255,255,255,0.7)",
                        float: "left",
                        marginRight: 3,
                    }}
                />
                <InfoDialog
                    style={{
                        zIndex:510,
                        background:"rgba(255,255,255,0.7)",
                        float: "left",
                        marginRight: 3,
                    }}
                    locale={locale}
                />


            </div>
    </div>
    );
}
