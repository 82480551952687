import LocalizedStrings from 'react-localization';

export let strings = new LocalizedStrings({
    en: {
        populationMobilityMonitor: "Population Mobility Monitor",
        choroplethBasedOn: "Choose choropleth map based on:",
        download: "Download",
        info: "Info",
        loading: "Loading data. Please wait....",
        preparing: "Preparing the map...",
        error: "Error",
        destination: "Destination",
        origin: "Origin",
        noMobilityData: "No mobility data",
        selectDataToDisplay :"Select data to display:",
        mobilePositioningDataInTartuCounty: "Mobile Positioning Data in Tartu County",
        tartuCountyBusData: "Tartu County bus data",
        tartuCityBusData: "Tartu City bus data",
        tartuCityBikeShareData: "Tartu City bike share data",
        selectTemporalScale: "Select temporal scale",
        monthlyMobility: "Monthly mobility",
        dailyMobility: "Daily mobility",
        hourlyMobility: "Hourly mobility",
        dateStart: "Date start",
        dateEnd: "Date end",
        hours: "Hours",
        hour: "Hour",
        days: "Days",
        day: "Day",
        date: "Date",
        month: "Month",
        selectMobilityType: "Select mobility flow type and click on the spatial unit on the map",
        incomingMobility: "Incoming mobility",
        outgoingMobility: "Outgoing mobility",
        unselectRegion: "Deselect region",
        clearMap: "Clear map",

        numberOfPeopleEndingTheirTrip: "Number of people ending their trip",
        numberOfPeopleStartingTheirTrip: "Number of people starting their trip",
        dailyAverageNumberOfPeopleEndingTheirTrip: "Daily average number of people ending their trip",
        dailyAverageNumberOfPeopleStartingTheirTrip: "Daily average number of people starting their trip",
        monthlyAverageNumberOfPeopleEndingTheirTrip: "Monthly average number of people ending their trip",
        monthlyAverageNumberOfPeopleStartingTheirTrip: "Monthly average number of people starting their trip",
        diffStartEndCounty: "The difference between the number of people starting and ending their trip",
        diffStartEndCity: "The difference between the number of people ending and starting their trip",

        numberOfPeopleTravellingBetweenTerritorialCommunities: "Number of people travelling between territorial communities",
        numberOfPeopleTravellingBetweenCityNeighbourhoods: "Number of people travelling between city neighbourhoods",
        numberOfPeople: "Number of people",
        numberOfTrips: "Number of trips",
        totalNumberOfTripsStartingAndEnding:  "Total number of trips starting and ending",


        dataResolution: "Data resolution",
        downloadShape: "Download also a shape-file for spatial units",
        bike: "bike",
        bus: "bus",
        usageAndInformation: "Population Mobility Monitor: Usage and Information",
        beta: "BETA version",
        dataType: "Data Type",
        noData: "no data",
        noBackground: "No background information",
    },
    et: {
        populationMobilityMonitor: "Rahvastiku liikuvusseire portaal",
        choroplethBasedOn: "Vali, mille alusel liikumisi kuvatakse",
        download: "Lae alla",
        info: "Info",
        loading: "Andmeid laetakse. Palun oodake...",
        preparing: "Kaart ettevalmistamisel...",
        error: "Viga",
        destination: "Sihtkoht",
        origin: "Lähtekoht",
        noMobilityData: "No mobility data",
        selectDataToDisplay : "Vali kuvatav andmestik:",
        mobilePositioningDataInTartuCounty: "Pendelränne Tartu maakonnas mobiilpositsioneerimise andmetel",
        tartuCountyBusData: "Tartu maakonnaliinid",
        tartuCityBusData: "Tartu linna ühistransport",
        tartuCityBikeShareData: "Tartu linna rattaringlus",
        selectTemporalScale: "Vali kuvatav ajaüksus ja periood",
        monthlyMobility: "Kuupõhine liikuvus",
        dailyMobility: "Päevane liikuvus",
        hourlyMobility: "Tunnipõhine liikuvus",
        dateStart: "Perioodi algus",
        dateEnd: "Perioodi lõpp",
        hours: "Kellaaeg",
        hour: "Tund",
        days: "Päevad",
        day: "Päev",
        date: "Kuupäev",
        month: "Kuu",
        selectMobilityType: "Vali liikumisvoo tüüp ja kliki kaardil",
        incomingMobility: "Sisenev liikuvus",
        outgoingMobility: "Väljuv liikuvus",
        unselectRegion: "Tühista ruumiüksuse valik",
        clearMap: "Puhasta kaart",

        numberOfPeopleEndingTheirTrip: "Reisi lõpetavate inimeste arv",
        numberOfPeopleStartingTheirTrip: "Reisi alustavate inimeste arv",
        dailyAverageNumberOfPeopleEndingTheirTrip: "Keskmine reisi lõpetavate inimeste arv päevas",
        dailyAverageNumberOfPeopleStartingTheirTrip: "Keskmine reisi alustavate inimeste arv päevas",
        monthlyAverageNumberOfPeopleEndingTheirTrip: "Keskmine reisi lõpetavate inimeste arv kuus",
        monthlyAverageNumberOfPeopleStartingTheirTrip: "Keskmine reisi alustavate inimeste arv kuus",
        diffStartEndCounty: "Reisi lõpetavate ja alustavate inimeste arvu erinevus kantides",
        diffStartEndCity: "Reisi lõpetavate ja alustavate inimeste arvu erinevus asumites",

        numberOfPeopleTravellingBetweenTerritorialCommunities: "Kantide vahel liikuvate inimeste arv",
        numberOfPeopleTravellingBetweenCityNeighbourhoods: "Asumite vahel liikuvate inimeste arv",

        numberOfPeople: "Inimeste arv",
        numberOfTrips: "Reiside arv",
        totalNumberOfTripsStartingAndEnding: "Algavate ja lõppevate reiside arv kokku",


        dataResolution: "Andmete ajaline täpsus",
        downloadShape: "Lae alla ka ruumiüksuste kiht",
        bike: "rattaringlus",
        bus: "ühistransport",
        usageAndInformation: "Rahvastiku liikuvusseire portaal",
        beta:"Beetaversioon",
        dataType: "Vali allalaetav andmestik",
        noData: "andmed puuduvad",
        noBackground: "Ilma taustakaardita",
    }
    ,
});