import {useLayoutEffect, useState} from "react"


export function useWinHeight() {
    const [height, setHeight] = useState();
    useLayoutEffect(()=>{
        const updateHeight = ()=>{setHeight(window.innerHeight);}
        window.addEventListener('resize', updateHeight);
        updateHeight();
        return ()=>{window.removeEventListener('resize', updateHeight);};
    })
    return height;
}