// Component to display bar-charts

import React from "react";
import PropTypes from "prop-types";
import {
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer,
    Label,
} from "recharts";
import { tartumaaNames, tartuNames } from "../Estonia/regionValues"
import {strings} from "../localization/strings";

/**
 * Component to display bar diagrams
 * @function BarDiagram
 * @kind React component
 * @param {Object} props - props
 * @param [props.data] {Array<Object>} - data array compatible with Recharts
 * @param [props.case] {("tartu"|"tartumaa")} - region
 * @param [props.mode] {("year"|"month"|"day")} - temporal scale
 * @param [props.id] {?(string|number)} - id of a chosen region
 * @param [props.showIn] {boolean} - show incoming mobility
 * @param [props.showOut] {boolean} - show outgoing mobility
 * @param [props.labels] {Array<string>} - labels for two data types displayed
 */
export default function BarDiagram(props) {
    let movers = strings.totalNumberOfTripsStartingAndEnding; //"Number of people";
    let moversIn = strings.numberOfPeopleEndingTheirTrip; // "Number of people ending their trip";
    let moversOut = strings.numberOfPeopleStartingTheirTrip;//"Number of people starting their trip";
    return(
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={props.data===undefined? []: props.data} >
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis dataKey={props.mode==="day"?"hour":"date"} height={30}>
                    <Label value={`${props.id!==null?props.case==="tartu"?tartuNames[props.id]:tartumaaNames[props.id] : " "}`}
                           offset={-10}
                           position="insideBottom"
                    style={{fontSize: 18}}/>
                </XAxis>

                <YAxis width={80}
                       // label={{ value: strings.numberOfTrips, angle: -90, position: 'insideLeft',}}
                       allowDecimals={false}
                >

                </YAxis>
                <Tooltip
                    //position={{x:-10, y:-10}}
                />
                {props.data!==undefined && props.id!==null && props.id!==undefined?
                    <Legend
                        align="left"
                        wrapperStyle={{
                            paddingLeft: 40,
                            paddingTop: 20,
                        }}
                    />
                    :
                    null
                }

                {/* no id//////////////////////////////////////////*/}
                {props.data!==undefined && (props.id===null || props.id===undefined) && props.labels===undefined?
                    <Bar dataKey={movers} fill={"#253494"}/>
                    :
                    null
                }
                {props.data!==undefined && (props.id===null || props.id===undefined) && props.labels!==undefined?
                    <Bar dataKey={movers+" ("+props.labels[0]+")"} stackId="a" fill={"#001494"}/>
                    :
                    null
                }
                {props.data!==undefined && (props.id===null || props.id===undefined) && props.labels!==undefined?
                    <Bar dataKey={movers+" ("+props.labels[1]+")"} stackId="a" fill={"#3347cc"}/>
                    :
                    null
                }
                {/*id and showOut////////////////////////////////////////////////*/}
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                props.showOut &&
                props.labels===undefined ?
                    <Bar dataKey={moversOut} fill={"#fa8e10"}/>
                    :
                    null
                }
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                props.showOut &&
                props.labels!==undefined ?
                    <Bar dataKey={moversOut+" ("+props.labels[0]+")"} stackId="c" fill={"#bf6600"}/>
                    :
                    null
                }
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                props.showOut &&
                props.labels!==undefined ?
                    <Bar dataKey={moversOut+" ("+props.labels[1]+")"} stackId="c" fill={"#fa8e10"}/>
                    :
                    null
                }
                {/* id and showIn////////////////////////////////////////////*/}
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                props.showIn &&
                props.labels===undefined?
                    <Bar dataKey={moversIn} fill={"#475ed2"}/>
                    :
                    null
                }
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                props.showIn &&
                props.labels!==undefined?
                    <Bar dataKey={moversIn+" ("+props.labels[0]+")"} stackId="b" fill={"#475ed2"}/>
                    :
                    null
                }
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                props.showIn &&
                props.labels!==undefined?
                    <Bar dataKey={moversIn+" ("+props.labels[1]+")"} stackId="b" fill={"#5773ff"}/>
                    :
                    null
                }


                {/*id and total !showIn && !showOut/////////////////////////////////////////////////*/}
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                !props.showOut &&
                !props.showIn &&
                props.labels===undefined?
                    <Bar dataKey={movers} fill={"#253494"}/>
                    :
                    null
                }
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                !props.showOut &&
                !props.showIn &&
                props.labels!==undefined?
                    <Bar dataKey={movers+" ("+props.labels[0]+")"} stackId="d" fill={"#253494"}/>
                    :
                    null
                }
                {props.data!==undefined &&
                props.id!==null &&
                props.id!==undefined &&
                !props.showOut &&
                !props.showIn &&
                props.labels!==undefined?
                    <Bar dataKey={movers+" ("+props.labels[1]+")"} stackId="d" fill={"#3347cc"}/>
                    :
                    null
                }
                {/*<Bar dataKey="pv" fill="#8884d8" onClick={(data, index)=>{console.log(data); console.log(index)}}/>*/}

            </BarChart>
        </ResponsiveContainer>
    )
}

BarDiagram.propTypes = {
    data: PropTypes.array,
    case: PropTypes.oneOf(["tartu", "tartumaa"]),
    mode:PropTypes.oneOf(["year", "month", "day"]),
    id: PropTypes.oneOfType( [PropTypes.number, PropTypes.string]),
    showIn: PropTypes.bool,
    showOut: PropTypes.bool,
    labels: PropTypes.arrayOf(PropTypes.string),
};