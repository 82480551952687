import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import PlayStopButton from "../buttons/PlayStopButton";
import {strings} from "../localization/strings";



const DaySlider = withStyles({
    markActive: props => ({"background-color": props.value.length!==undefined ? undefined : "currentColor"}),
    track: props => ({opacity: props.value.length!==undefined?undefined:0.0}),
    markLabelActive:  props => ({"color": props.value.length!==undefined ? undefined : "rgba(0, 0, 0, 0.84)"}),
    markLabel: {"color":  "rgba(0, 0, 0, 0.84)"}
})(Slider)



export default function SliderController(props) {
    const dayMode = props.mode === "day";
    const [value, setValue] = useState(props.value);
    // all the effects cannot be calulated immideatly after the slider changed
    // thus it only call handleChange after the change has committed
    useEffect(()=>{
        setValue(props.value);
    },[props.value])
    return (
        <React.Fragment>
            <br/>
            <PlayStopButton on={props.animate} handleClick={props.handleButtonAnimate}/><br/><br/>
            {dayMode?
                <DaySlider
                    aria-labelledby="discrete-slider"
                    value={value}
                    onChange={(e, val)=>{setValue(val)}}
                    onChangeCommitted={props.handleChange}
                    valueLabelDisplay="on"
                    step={1}
                    marks={[...Array(24).keys()].map(k=>({value:k, label: k%4===0? `${k}:00`:undefined }))}
                    min={0}
                    max={23}
                    getAriaLabel={(index)=>`${index}:00`}
                    getAriaValueText={(value, index)=>`${index}:00`}
                />
                :
                <DaySlider
                    aria-labelledby="discrete-slider"
                    value={value}
                    onChange={(e, val)=>{setValue(val)}}
                    onChangeCommitted={props.handleChange}
                    valueLabelDisplay="on"
                    step={1}
                    marks
                    min={1}
                    max={(new Date(props.date.getFullYear(), props.date.getMonth()+1, 0)).getDate()}
                />}
            <Typography variant="caption" gutterBottom>
                {props.mode==="day"? strings.hours : strings.days}
            </Typography><br/>

        </React.Fragment>
    );
}

SliderController.propTypes = {
    mode: PropTypes.oneOf(["day", "month"]),
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
    handleChange: PropTypes.func,
    date: PropTypes.instanceOf(Date),
    handleButtonAnimate: PropTypes.func,
    animate: PropTypes.bool,
}