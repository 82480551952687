import React from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { strings } from "../localization/strings";


export default function DayMonthRangeController(props) {
    return(
        <React.Fragment>
            <FormControlLabel
                control ={<Switch
                    name="enableYear"
                    checked={props.state.enableYear}
                    onChange={props.handleDateMonthRangeSwitchChange}
                    color="primary"
                    size="small"
                />}
                label={ strings.monthlyMobility}
                disabled={props.disabled}
            /><br/>
            <FormControlLabel
                control ={<Switch
                    name="enableMonth"
                    checked={props.state.enableMonth}
                    onChange={props.handleDateMonthRangeSwitchChange}
                    color="primary"
                    size="small"
                    disabled={props.disableDay}
                />}
                label={strings.dailyMobility}
                disabled={props.disabled || props.disableDay}
            /><br/>
            <FormControlLabel
                control ={<Switch
                    name="enableDay"
                    checked={props.state.enableDay}
                    onChange={props.handleDateMonthRangeSwitchChange}
                    color="primary"
                    size="small"
                    disabled={props.disableDay}
                />}
                label={strings.hourlyMobility}
                disabled={props.disabled || props.disableDay}
            />
        </React.Fragment>
    );
}

DayMonthRangeController.propTypes = {
    disabled: PropTypes.bool,
    handleDateMonthRangeSwitchChange: PropTypes.func,
    state: PropTypes.exact({
        enableYear: PropTypes.bool,
        enableMonth: PropTypes.bool,
        enableDay: PropTypes.bool,
    }),
    disableDay: PropTypes.bool,
};

DayMonthRangeController.defaultProps = {
    state: {
        enableYear: true,
        enableMonth: false,
        enableDay: false,
    },
    disableDay: false,
};
