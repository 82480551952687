import tartumaaCentroidsData from "../data/tc_centroid.json";
import tartuCentroidsData from "../data/Tartu_centroids.json";


export const tartumaaCentroids = tartumaaCentroidsData["features"].reduce((obj, item)=>{
        const coordinates = item["geometry"]["coordinates"];
        obj[item["properties"]["kood"]] = [coordinates[1], coordinates[0]];
        return obj;
    }, {}
    );

export const tartumaaNames = tartumaaCentroidsData["features"].reduce((obj, item) => {
        obj[item["properties"]["kood"]] = item["properties"]["nimi"]
        return obj;
    }, {}
    );

export const tartuCentroids = tartuCentroidsData["features"].reduce((obj, item)=>{
        const coordinates = item["geometry"]["coordinates"];
        obj[item["properties"]["gid"]] = [coordinates[1], coordinates[0]];
        return obj;
    }, {}
    );

export const tartuNames = tartuCentroidsData["features"].reduce((obj, item) => {
        obj[item["properties"]["gid"]] = item["properties"]["asum"]
        return obj;
    }, {}
    );