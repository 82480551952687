import React from "react";
import {ArrowForwardIos} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";


export default function LeftButton(props){
    return(
        <IconButton onClick={props.onClick} disabled={props.disabled}>
            <ArrowForwardIos/>
        </IconButton>
    );
}
