/**
 * Generates the string with date
 * @param date
 * @returns {string}
 */
export function toDay(date) {
    return `${date.getFullYear()}-${("0"+(date.getMonth()+1)).slice(-2)}-${("0"+(date.getDate())).slice(-2)}`;
}

/**
 * Generates the string with month
 * @param date
 * @returns {string}
 */
export function toMonth(date) {
    return `${date.getFullYear()}-${("0"+(date.getMonth()+1)).slice(-2)}`;
}

export const urlMinDayHour = "T$00%3A00%3A00";
export const urlMaxDayHour ="T$23%3A00%3A00";

/**
 * Returns the earliest date
 * @param {Date} date1
 * @param {Date} date2
 * @returns {(undefined|Date)}
 */
export function minDate(date1, date2) {
    if (date1 !== undefined && date2 !== undefined){
        return date1.getTime()<date2.getTime()?new Date(date1) : new Date(date2);
    } else if (date1 === undefined){
        return date2;
    } else if (date2 === undefined) {
        return date1;
    } else {
        return undefined;
    }
}

/**
 * Returns the latest date
 * @param {Date} date1
 * @param {Date} date2
 * @returns {undefined|Date}
 */
export function maxDate(date1, date2) {
    if (date1 !== undefined && date2 !== undefined){
        return date1.getTime()>date2.getTime()?new Date(date1) : new Date(date2);
    } else if (date1 === undefined){
        return date2;
    } else if (date2 === undefined) {
        return date1;
    } else {
        return undefined;
    }
}

/**
 * Intersection of date boundaries
 * @param {Array<Date>} boundary1
 * @param {Array<Date>} boundary2
 * @returns {Array<Date>}
 */
export function intersectDateBoundaries(boundary1, boundary2) {
    return ([
        new Date(maxDate(boundary1[0], boundary2[0])),
        new Date(minDate(boundary1[1], boundary2[1]))
    ]);
}

/**
 * Calculates difference date2 - date1 in days
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number}
 */
export function dayDiff(date1, date2) {
    return Math.floor(
        (date2.getTime() - date1.getTime()) / (24*3600000) + 1
    );
}

/**
 * Calculates difference date2 - date1 in months
 * @param {Date} date1
 * @param {Date} date2
 * @returns {number}
 */
export function monthDiff(date1, date2) {
    return (
        (date2.getFullYear() - date1.getFullYear())*12 + date2.getMonth() - date1.getMonth() + 1
    );
}

/**
 * Generates an array with values for each day in the date interval
 * @param {Array<Date>} date
 * @returns {Date[]}
 */
export function generateDaysRange(date) {
    const diff = dayDiff(...date);
    return [...Array(diff).keys()].map(k=>new Date(date[0].getFullYear(), date[0].getMonth(), date[0].getDate()+k));
}

/**
 * Generates an array with values for each month in the date interval (first day of the month is chosen)
 * @param {Array<Date>} date
 * @returns {Date[]}
 */
export function generateMonthRange(date) {
    const diff = monthDiff(...date)||1;

    return [...Array(diff).keys()].map(k=>new Date(date[0].getFullYear(), date[0].getMonth()+k, 1))
}

/**
 * Does not let the date range to exceed some boundaries. mode "year":keep the date interval less than 13 months, mode "month": keep the date interval less than 31 days
 * @param {Array<Date>} date - date interval to be normalized
 * @param {Array<Date>} boundaries - allowed date boundaries
 * @param {("year"|"month")} mode - temporal level
 *
 * @returns {Array<Date>}
 */
export function normalizeInterval(date, boundaries, mode) {
    // mode ["year", "month"]
    // year:
    // keep the date interval less than 13 months
    // month:
    // keep the date interval less than 31 days

    let newSecondDate;
    if (mode==="year") {
        const diff = monthDiff(...date);

        if (diff < 1) {
            newSecondDate = new Date(date[0].getFullYear(), date[0].getMonth()+1, 0);
        } else if (diff > 12){
            newSecondDate = new Date(date[0].getFullYear(), date[0].getMonth()+12, 0);
        } else {
            newSecondDate = date[1]
        }
        newSecondDate = minDate(newSecondDate, boundaries[1]);
        return [date[0], newSecondDate];


    } else if (mode==="month") {
        const diff = dayDiff(...date);
        if (diff < 1) {
            newSecondDate = date[0];
        } else if (diff > 30){
            newSecondDate = new Date(date[0].getFullYear(), date[0].getMonth(), date[0].getDate()+30)
        } else {
            newSecondDate = date[1]
        }
        newSecondDate = minDate(newSecondDate, boundaries[1]);
        return [date[0], newSecondDate];
    } else {
        return date;
    }

}
