import {dayDiff, generateDaysRange, generateMonthRange, monthDiff, toDay, toMonth} from "../utils/dateStringUtils";
import {strings} from "../localization/strings";
import {aggregateByFieldAsync} from "../utils/aggregationUtils";

/**
 * Ads missing hours to the table with 0 values
 * @param {object} table - {hour: value}
 */
function enrichTableHours(table) {
    [...Array(24).keys()]
        .map((val)=>("0"+val).slice(-2))
        .forEach((key)=>{
            if (table[key] === undefined) {
                table[key] = 0;
            }
        });
}

/**
 * Ads missing days to the table with 0 values
 * @param {object}      table - {date: value}
 * @param {Array<Date>} date - date boundaries of the selected data
 */
function enrichTableDays(table, date) {
    const interval = dayDiff(...date)<7?
        [new Date(date[1].getFullYear(), date[1].getMonth(), date[1].getDate()-6) , date[1]]
        :
        date;
    generateDaysRange(interval)
        .map(toDay)
        .forEach(day=>{
            if (table[day] === undefined) {
                table[day] = 0;
            }
        });

}

/**
 * Ads missing month to the table with 0 values
 * @param {object}      table - {month: value}
 * @param {Array<Date>} date - date boundaries of the selected data
 * @param {Array<Date>} interval - interval that has to be enriched
 */
function enrichTableMonth(table, date, interval) {
    if (interval===undefined){
        interval = monthDiff(...date)<12?
            [new Date(date[1].getFullYear(), date[1].getMonth()-11, 1), date[1]]
            :
            date;
    }
    generateMonthRange(interval)
        .map(toMonth)
        .forEach(month=>{
            if (table[month] === undefined) {
                table[month] = 0;
            }
        });

}


/**
 * Gets the numeric representation of the date string for sorting
 * @param {string} dayString
 * @returns {number}
 */
function toNumDay(dayString) {return parseInt(dayString.slice(0,4) + dayString.slice(5,7) + dayString.slice(8,10))}

/**
 * Gets the numeric representation of the date string for sorting
 * @param {string} monthString
 * @returns {number}
 */
function toNumMonth(monthString) {return parseInt(monthString.slice(0,4) + monthString.slice(5,7))}

/**
 * Reformats yyyy-mm-dd to dd-mm-yyyy
 * @param {string} dayString
 * @returns {string}
 */
function reformatDay(dayString) {return `${dayString.slice(8,10)}-${dayString.slice(5,7)}-${dayString.slice(0,4)}`}

/**
 * Reformats yyyy-mm to mm-yyyy
 * @param monthString
 * @returns {string}
 */
function reformatMonth(monthString) {return `${monthString.slice(5,7)}-${monthString.slice(0,4)}`}


/**
 * Prepares data for bar diagram
 * @param {Array}                   data - JSON-like array of OD tables
 * @param {("tartu"|"tartumaa")}       region - type of geographical region
 * @param {("day"|"month"|"year")}    temporalLevel - temporal resolution of data to be displayed
 * @param {number|string|null}      id - id to aggregate data for a selected region, if null - general data is shown
 * @param {Array<Date>}             date - date boundaries of the selected data
 * @param {boolean}                 showIn - if id is not null, show incoming mobility
 * @param {boolean}                 showOut - if id is not null, show outgoing mobility
 * @param {Array<Date>}             interval - exceeds or equal to data boundaries, interval to be shown on the barDiagram, missing data will be considered as 0
 * @returns {Promise<Array>} data array suitable for Recharts component
 */
export async function prepareDataBarDiagramAsync(data, region, temporalLevel, id, date, showIn, showOut, interval) {
    let movers = strings.totalNumberOfTripsStartingAndEnding; //"Number of people";
    let moversIn = strings.numberOfPeopleEndingTheirTrip; // "Number of people ending their trip";
    let moversOut = strings.numberOfPeopleStartingTheirTrip;//"Number of people starting their trip";

    const timeField = temporalLevel==="day"? "hour":"date";
    const endID = region === "tartu" ? "end_neighborhood_id" : "end_tc_code";
    const startID = region === "tartu" ? "start_neighborhood_id" : "start_tc_code";
    const enabledId = (id !== null);

    const enrichFunction =
        temporalLevel==="day"? enrichTableHours:
            temporalLevel==="month"? enrichTableDays:
                temporalLevel==="year"?
                    interval!== undefined?
                        (table, date)=>{enrichTableMonth(table, date, interval)}
                        :
                        enrichTableMonth
                    : undefined;


    const sliceVal = temporalLevel==="day"? [11,13]:
        temporalLevel==="month"? [0,10]:
            temporalLevel==="year"? [0,7]: undefined;

    const compareFn =  temporalLevel==="day"? (a,b) => parseInt(a[timeField]) - parseInt(b[timeField]):
        temporalLevel==="month"? (a,b) => toNumDay(a[timeField]) - toNumDay(b[timeField]):
            temporalLevel==="year"? (a,b) => toNumMonth(a[timeField]) - toNumMonth(b[timeField]):
                undefined;

    const reformatFn = temporalLevel==="day"? val=>val:
        temporalLevel==="month"? val=>({...val,"date":reformatDay(val["date"])}):
            temporalLevel==="year"? val=>({...val,"date":reformatMonth(val["date"])}):
                undefined;

    if (showOut||showIn) {
        const filterIn = enabledId? (val) => parseInt(val[endID]) === parseInt(id) : () => true;
        const filterOut = enabledId? (val) => parseInt(val[startID]) === parseInt(id) : () => true;
        const tableIn =  await aggregateByFieldAsync(
            data.map(v => v.map(val => ({...val, [timeField]: val[timeField].slice(...sliceVal)})).filter(filterIn)),
            true,
            timeField,
            undefined,
            true,
        );
        const tableOut = await aggregateByFieldAsync(
            data.map(v => v.map(val => ({...val, [timeField]: val[timeField].slice(...sliceVal)})).filter(filterOut)),
            true,
            timeField,
            undefined,
            true
        );
        await enrichFunction(tableOut, date);
        await enrichFunction(tableIn, date);
        return Object.entries(tableIn)
            .map(v => ({
                [timeField]: v[0],
                [moversIn]: v[1],
                [moversOut]: tableOut[v[0]],
            }))
            .sort(compareFn)
            .map(reformatFn);

    } else {
        const filter = enabledId ?
            (val) => parseInt(val[endID]) === parseInt(id) || parseInt(val[startID]) === parseInt(id)
            :
            () => true;
        const table = await aggregateByFieldAsync(
            data.map(v => v.map(val => ({...val, [timeField]: val[timeField].slice(...sliceVal)})).filter(filter)),
            true,
            timeField,
            undefined,
            true,
        );
        await enrichFunction(table, date);
        //await console.log(table);
        return Object.entries(table)
            .map(v => ({[timeField]: v[0], [movers]: v[1]}))
            .sort(compareFn)
            .map(reformatFn);
    }
}

/**
 * Prepares data for bar diagram from 2 data types to be displayed in staked manner
 * @param {Array}                   data - JSON-like array of OD tables
 * @param {("tartu"|"tartumaa")}    region - type of geographical region
 * @param {string}                  temporalLevel - "day"|"month"|"year" temporal resolution of data to be displayed
 * @param {?(number|string)}        id - id to aggregate data for a selected region, if null - general data is shown
 * @param {Array<Date>}             date - date boundaries of the selected data
 * @param {boolean}                 showIn - if id is not null, show incoming mobility
 * @param {boolean}                 showOut - if id is not null, show outgoing mobility
 * @param {Array<Date>}             interval - exceeds or equal to data boundaries, interval to be shown on the barDiagram, missing data will be considered as 0
 * @param {Array<string>}           labels - labels corresponding to the tables in data
 * @returns {Promise<Array>} data array suitable for Recharts component
 */
export async function prepareDataBarDiagramStackedAsync(data, region, temporalLevel, id, date, showIn, showOut,interval, labels) {
    // prepares data for BarDiagram from 2 data types to be displayed in staked manner
    if (data.length<2){return []}
    const data0 = await prepareDataBarDiagramAsync([data[0]], region, temporalLevel, id, date, showIn, showOut, interval);
    const data1 = await prepareDataBarDiagramAsync([data[1]], region, temporalLevel, id, date, showIn, showOut, interval);
    // changes the names of the object fields adding label to each name except "hour" and "date"
    function insertLabel(data, label) {
        return data
            .map((val) => {
                return Object.entries(val)
                    .reduce((obj, v) => {
                        if (v[0] === "hour" || v[0] === "date"){
                            obj[v[0]] = v[1];
                        } else {
                            obj[v[0]+" ("+label+")"] = v[1];
                        }
                        return obj;
                    }, {})
            });
    }

    const labeledData0 = await insertLabel(data0, labels[0]);
    const labeledData1 = await insertLabel(data1, labels[1]);

    return labeledData0.map((val, i)=>({
        ...val,
        ...labeledData1[i]
    }));
}