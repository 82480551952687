import {dayDiff, monthDiff} from "../utils/dateStringUtils";
import { aggregateByFieldAsync } from "../utils/aggregationUtils";
import {calculateColors, calculateColorsDif} from "../utils/colorSchemeUtils";
import {aggregateCountPosNegAsync} from "../utils/aggregationPosNeg";

/**
 *
 * @param {Array} data - array of JSON-like tables
 * @param {"tartu"|"tartumaa"} region - region name
 * @param {"day"|"month"|"year"} temporalLevel  - temporal level to be prepared
 * @param {Array<Date>} date - date boundaries of the data
 * @param {boolean} destination - whether to calculate incoming mobility(true), of outgoing mobility(false)
 * @param {boolean} origin - whether to calculate incoming mobility(true), of outgoing mobility(false) of saldo
 * @param {number} sliderValue - value of a SliderController
 * @param {Array<string>} colorMap - array of colors corresponding to bins
 * @param {Array<number>} bins - array of values used in the choropleth map
 * @returns {Promise<{table: Object, newScheme: Object, newBins: Array<string>}>}
 */
export async function prepareColorScheme(data, region, temporalLevel, date, destination, origin, sliderValue, colorMap, bins) {
    const endID = region === "tartu" ? "end_neighborhood_id" : "end_tc_code";
    const startID = region === "tartu" ? "start_neighborhood_id" : "start_tc_code";
    const filter = temporalLevel==="day"? (val) => sliderValue===parseInt(val["hour"].slice(11,13)) :
        () => true;
    const divider = temporalLevel==="day"? 1:
        temporalLevel==="month"? dayDiff(...date):
            temporalLevel==="year"? monthDiff(...date):
                undefined;

    let table, newSchemeBins;
    if (origin && destination) {
        table = await aggregateCountPosNegAsync(
            data.map(v => v.filter(filter)),
            true,
            [endID, startID],
            1,
            false,
        )
        newSchemeBins = await calculateColorsDif(table, colorMap, bins);
    }else if(!(origin || destination)){
        table = {};
        newSchemeBins = [undefined, undefined];
    } else {
        table = await aggregateByFieldAsync(
            data.map(v => v.filter(filter)),
            true,
            destination?endID:startID,
            divider,
            false,
        );
        newSchemeBins = await calculateColors(table, colorMap, bins);
    }

    // const [newScheme, newBins] = await calculateColors(table, colorMap, bins);

    return {
        table: table,
        // newScheme: newScheme,
        newScheme: newSchemeBins[0],
        // newBins: newBins,
        newBins: newSchemeBins[1]
    }
}


