import React from "react";
import logo from "../data/s-pro-mara-logo-banner EU Nor.png";

/**
 * Displays logo
 * @funcion MaraLogo
 * @kind React component
 */
export default function MaraLogo() {
    return(
        <div style={{
            background:"rgba(255,255,255,0.5)",
            position: "fixed",
            top: 10,
            left: 410,
            width: "20%",
            zIndex: 500,
            borderRadius: 10
        }}>
            <img src={logo} alt={"Logo"}
                 style={{zIndex: 500,
                     width: "100%",
                 }}
            />
        </div>
    );
}