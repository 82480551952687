import {LayersControl, TileLayer} from "react-leaflet";
import React from "react";
import {strings} from "../localization/strings";

/**
 * Operates background map layers
 * @function MapTileLayers
 * @kind React component
 */
const MapTileLayers = () => {
    return(
        <LayersControl position="topright">
            <LayersControl.BaseLayer name="OpenStreetMap.Mapnik" checked={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="CARTO db" checked={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png"
                />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name={strings.noBackground} checked={false}>
                <TileLayer url={"undefined"}/>
            </LayersControl.BaseLayer>

        </LayersControl>);
};

export default MapTileLayers;