// import {Path, useMap, } from "react-leaflet";
import { createPathComponent } from '@react-leaflet/core';
import * as L from "leaflet";
import "@elfalem/leaflet-curve";
import isEqual from "fast-deep-equal";


function addMiddlePoint(pointSet, curvness) {
    const x1 = pointSet[0]['x'],
        y1 = pointSet[0]['y'],
        x2 = pointSet[1]['x'],
        y2 = pointSet[1]['y'];
    const x = x2-x1;
    const y = y2-y1;

    const abs = Math.sqrt(Math.pow(x,2) + Math.pow(y,2));
    const xNorm = x/abs;
    const yNorm = y/abs;

    const [xR, yR] = [-yNorm, xNorm];
    const x3 = (x1 + x2)/2 + xR/curvness * abs;
    const y3 = (y1 + y2)/2 + yR/curvness * abs;
    return [
        {x: x1, y: y1},
        {x: x3, y: y3},
        {x: x2, y: y2}
    ];
}


function createCurveArc(props, context) {
    const { positions, curvness, popup,...options } = props;
    let instance;
    if (isEqual(props.positions[0], props.positions[1])){
        console.log("arc with 0 length");
        instance = L.curve([props.positions[0],props.positions[0],props.positions[0]]);
    } else {
        const points = positions
            .map((lngLat) => context.map.project(lngLat));
        const curvedPositions = addMiddlePoint(points, curvness)
            .map((point) => context.map.unproject(point))
            .map((latLng) => [latLng['lat'], latLng['lng']]);
        const path= [
            'M', curvedPositions[0],
            'Q', curvedPositions[1],
            curvedPositions[2]
        ];
        const leafletElement = L.curve(path, options);
        if (popup) {
            leafletElement.bindTooltip(`${popup}`);
            leafletElement.on("mouseover", (e) => {
                e.target.openTooltip();
            });
            leafletElement.on("mouseout", (e) => {
                e.target.closeTooltip();
            });
            leafletElement.on("mousemove", (e) => {
                e.target.getTooltip().setLatLng(e.latlng);
            });
            leafletElement.on("click", (e) => {
                e.target.getTooltip().setLatLng(e.latlng);
            })
        }
        // https://github.com/Leaflet/Leaflet/pull/6941
        // bug in the leaflet update style
        leafletElement._updateBounds = ()=>{};
        instance = leafletElement;
    }
    return { instance, context }
  }
  
function updateCurveArc(instance, props, prevProps) {
    // we do not expect arc to rerender
}
  
const CurveArc = createPathComponent(createCurveArc, updateCurveArc);
export default CurveArc;
