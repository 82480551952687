import React from "react";
import PropTypes from 'prop-types';
import { GeoJSON } from "react-leaflet";
import hash from "object-hash";

// data import
import tartuData from '../data/Tartu_polygons.json'

const lessOne = (val) => val===undefined? "" :
    val<1 && val>0?">0" :
        val>-1 && val<0? "<0":
            Math.round(val);

/**
 * Displays Tartu county
 * @function Tartu
 * @kind React component
 * @param {Object} props - props
 * @param [props.colorScheme] {Object} - {regionId: color} corresponding color
 * @param [props.regionValues] {Object} - {regionId: number} amount of people
 * @param [props.id] {?(string|number)} - id of the selected region
 * @param [props.setID] {callback} - set the region id when clicked
 */
export default function Tartu(props) {
    const onEachFeature = (feature, layer) => {
        if (props.colorScheme===undefined) {
            layer.setStyle({
                "weight": 4,
                "fillColor": "#ffffff",
                "fillOpacity": 0.0,
                "color": "#e6e6e6"
            });
            layer.bindTooltip(`<div class="any-text">${feature.properties.asum}</div>`);
            layer.on('mousemove', (e)=>{
                e.target.getTooltip().setLatLng(e.latlng);
            });
        } else {


            layer.bindTooltip(`<div class="any-text">${feature.properties.asum} ${lessOne(props.regionValues[feature.properties.gid])}</div>`);
            layer.setStyle({
                "weight": feature.properties.gid===props.id? 4 : 1,
                "fillColor": props.colorScheme[feature.properties.gid] || "#ffffff",
                "fillOpacity": 0.6,
                "color": "#e6e6e6"
            });
            const fillOpacity = layer.options.fillOpacity;
            layer.on('mouseover', ()=>{
                layer.setStyle({
                    "fillOpacity": 1.0,
                });
            });
            layer.on('mouseout', ()=>{
                layer.setStyle({
                    "fillOpacity": fillOpacity,
                });
            });
            layer.on('mousemove', (e)=>{
                e.target.getTooltip().setLatLng(e.latlng);
            });
            layer.on('click', ()=>{
                props.setID(feature.properties.gid)
            });
        }
    }

    // key is passed to rerender the GeoJSON element, it does not update on props update
    const key = hash({colorScheme: props.colorScheme, regionValues: props.regionValues, id: props.id});
    return(
        <GeoJSON key={key} data={tartuData} onEachFeature={onEachFeature}/>
    );
}

Tartu.propTypes = {
    setID: PropTypes.func,
    colorScheme: PropTypes.object,
    regionValues: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Tartu.defaultProps = {
    // colorScheme: {},
    setID: ()=>{},
    regionValues:{},
};