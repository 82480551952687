import { useEffect, useState } from 'react';
import {useMap} from 'react-leaflet';


/**
 * Updates zoom and center on the MapContainer if changed
 * @function ViewPortUpdate
 * @kind React component
 * @param {Object} props
 * @param [props.center] {LatLng} - center, Leaflet LatLng object
 * @param [props.zoom] {number} - zoom
 * @param [props.cityLevel] {bool} - true if city level, false if county level 
 */
export default function ViewPortUpdate(props) {
    const map = useMap();
    const [level, setLevel] = useState(null);
    useEffect(() => {
        if (level !== props.cityLevel){
            map.setView(props.center, props.zoom);
            setLevel(props.cityLevel);
        }
    }, [props.cityLevel,
        level,
        map,
        props.center, 
        props.zoom
    ])
    // map.setView(props.center, props.zoom);
    return null;
}