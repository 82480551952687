import React from "react";
import PropTypes from "prop-types";
import {strings} from "../localization/strings";


// const dressInf = (num) => isFinite(num)? num: num>0? "+∞" : "-∞";
/**
 * Legend for choropleth
 * @function LegendMovers
 * @kind React component
 * @param {Object} props - props
 * @param [props.bins] {Array<number>} - array of bins
 * @param [props.colors] {Array<string>} - array of colors corresponding to bins,
 * @param [props.noInfo] {string} - text to display for absence of information
 * @param [props.text] {string} - caption
 * @param [props.style] {Object} - style object
 */
export default function LegendMovers (props) {
    const grades = [];
    const signs = ["<","<","~",">",">"];
    // const signs = props.diffMode? ["<","<","~",">",">"] : [">", ">", ">", ">", ">"];
    const strVals =
        props.diffMode?
            props.bins.map((b,i)=>`${signs[i]} ${b}`)
            :
            props.bins.map((b,i) => {
            if (i===0) {
                return `${b} - ${props.bins[i+1]}`
            } else if (i===props.bins.length-1) {
                return `> ${b}`
            } else {
                return `${b+1} - ${props.bins[i+1]}`
            }
        })
    for (let i=4; i>-1; i--) {
        grades.push(
            (<div style={{background: props.colors[i], width: 18, height: 18, float: "left",  marginRight: 2, opacity: 0.9}}
                  key={2*i}/>)
        );
        // grades.push(<span key={2*i+1}> {`${signs[i]}${dressInf(props.bins[i+1])}`}<br/></span>);
        grades.push(<span key={2*i+1}> {strVals[i]}<br/></span>);
    }
    if (props.noInfo !== undefined) {
        const a = (
            <div style={{
                display: "flex",
                flexDirection: "row",
            }}
                 key={5 * 2}>
            <div style={{
                background: props.noInfo,
                width: 18,
                height: 18,
                float: "left",
                marginRight: 2,
                opacity: 0.9,
                border: "1px solid lightblue",
                flexShrink: 0,
            }}
                 key={5 * 2}/>
            <span key={2 * 5 + 1}>
                <div>
                   {strings.noData}
                </div>

                {/*<br/>*/}
            </span>
        </div>);
        grades.push(a);
    }


    return(
        <div
            className={"any-text"}
            style={{
                width:"9%",
                textSize: 9,
                right: 10,
                background: "rgba(255,255,255,0.7)",
                position: "fixed",
                zIndex: 510,
                padding:10,
                borderRadius:20 ,
                ...props.style}}
        >
            <span>{props.text}</span><br/>
            {grades}
        </div>
    );
}

LegendMovers.propTypes = {
    bins: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.arrayOf(PropTypes.string),
    noInfo: PropTypes.string,
    diffMode: PropTypes.bool,
    text: PropTypes.string,
    style: PropTypes.object,
}
LegendMovers.defaultProps = {
    bins: [0, 10, 20, 30, 50],
    diffMode: false,
}