import React from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';



export default function PlayStopButton(props) {
    return(<IconButton onClick={props.handleClick} disabled={props.disabled}>
        {props.on? <StopIcon/>:<PlayArrowIcon/>}
    </IconButton>)
}
PlayStopButton.propTypes = {
    on: PropTypes.bool,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
};