import React from "react";
import PropTypes from "prop-types";
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { strings } from "../localization/strings";
import Grid from "@material-ui/core/Grid";
import {minDate, toDay} from "../utils/dateStringUtils";
import LeftButton from "../buttons/LeftButton";
import RightButton from "../buttons/RightButton";

locale.options.weekStartsOn = 1;

export default function DateSelectionController(props) {
    const dayLevel = props.variant === "day" || props.variant === "month";
    const monthMode = props.variant === "month";
    const yearMode = props.variant === "year";
    const maxEndDate = monthMode? minDate(new Date(props.selectedDate[0].getFullYear(), props.selectedDate[0].getMonth(), props.selectedDate[0].getDate()+30),
        props.dateBoundaries[1])
        :yearMode? minDate(new Date(props.selectedDate[0].getFullYear(), props.selectedDate[0].getMonth()+12, 0),
            props.dateBoundaries[1])
        :props.dateBoundaries[1];

    return(
        <React.Fragment>
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={locale}
            >
                <Grid container spacing={1}>
                    <Grid item xs={props.enableArrows?8:6} container direction="row">
                        {props.enableArrows?<Grid item xs={2}
                        ><LeftButton onClick={props.handleLeftArrow} disabled={props.disabled}/></Grid>
                        :
                        null}
                        <Grid item xs={props.enableArrows?8:12}>
                            <KeyboardDatePicker
                                autoOk
                                label={props.enableRange?strings.dateStart: strings.date}
                                format={dayLevel?"dd-MM-yyyy":"MM-yyyy"}
                                views={dayLevel?undefined:["year", "month"]}
                                openTo={dayLevel?undefined:"month"}
                                minDate={new Date(props.dateBoundaries[0])}
                                maxDate={new Date(props.dateBoundaries[1])}
                                value={props.selectedDate[0]}
                                onChange={props.handleDateChange(0)}
                                onAccept={props.handleDateChange(0)}
                                variant={"inline"}
                                disabled={props.disabled}
                            />
                        </Grid>

                        {props.enableArrows?<Grid item xs={2}
                        ><RightButton onClick={props.handleRightArrow} disabled={props.disabled}/></Grid>
                        :
                        null}

                    </Grid>
                    {props.enableRange?<Grid item xs={6}>
                        <KeyboardDatePicker
                            autoOk
                            label={strings.dateEnd}
                            format={dayLevel ? "dd-MM-yyyy" : "MM-yyyy"}
                            views={dayLevel ? undefined : ["year", "month"]}
                            openTo={dayLevel ? undefined : "month"}
                            minDate={new Date(props.selectedDate[0])}
                            maxDate={props.boundSecondDate?new Date(maxEndDate): props.dateBoundaries[1]}
                            maxDateMessage={`date should not exceed ${toDay(maxEndDate)}`}
                            value={props.selectedDate[1]}
                            onChange={props.handleDateChange(1)}
                            onAccept={props.handleDateChange(1)}
                            variant={"inline"}
                            disabled={props.disabled}
                        />
                    </Grid> :null}
                </Grid>
            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
}

DateSelectionController.propTypes = {
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(["day", "month", "year"]),
    enableRange: PropTypes.bool,
    handleDateChange: PropTypes.func,
    selectedDate: PropTypes.arrayOf(Date),
    dateBoundaries: PropTypes.arrayOf(Date),
    boundSecondDate: PropTypes.bool,
    enableArrows: PropTypes.bool,
    handleLeftArrow: PropTypes.func,
    handleRightArrow: PropTypes.func,
};

DateSelectionController.defaultProps = {
    variant: "day",
    enableRange: true,
    dateBoundaries: [undefined, undefined],
    boundSecondDate: true,
    enableArrows: false,
};