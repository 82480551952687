//

import React from "react";
import PropTypes from "prop-types";
// import CurveArc from "./CurveArc";
// import CircleWithTooltip from "./CircleWithTooltip";
import { CircleMarker, FeatureGroup} from "react-leaflet";
import * as tinycolor from "tinycolor2";
import hash from "object-hash";
import CurveArcTouch from "./CurveArcTouch";

// const binsToWidth2 = (val)=>{
//     const bins = [1, 5, 10, 50, 500, 5000, 50000, 100000];
//     const step = 1.5;
//     let i = -step;
//     for (let b of bins){
//         i += step;
//         if (b >= val){
//             break
//         }
//     }
//     // if (i!==0){
//     //     i += step;
//     // }
//     return i;
// }


const binsToWidth = (val, bins) => {
    // bins = bins || [0, 5, 10, 50, 500, 5000, 50000, 100000];
    const step = 1.5;
    let i = bins.length -1;
    while (bins[i]> val) {
        i--
    }
    i = i + (val===0?0:1);
    if (i===0) {
        return 0;
    } else if (val < bins[1]) {
        return step;
    } else if(val >= bins[bins.length-1]) {
        return (bins.length-1)*step;
    } else {
        return (i-1 + (val - bins[i-1])/(bins[i]-bins[i-1])) * step;
    }
}

const lessOne = (val) => val<1? ">0": Math.round(val);

export const example = {
    startCentroid: [58.0846584722463, 26.15680604235189, ],
    startName: "startName",
    targetCentroids: [
        [[58.305373652908855, 26.801732540103558, ], 10, 10],
        [[58.08170765696449, 26.966269015476733, ], 80, 6],
        [[59.45267520745042, 26.130767344705625,], 15, 30],
        [[58.130044500097995, 25.353557634115628, ], 20, 10],
    ],
    targetNames: ["name1", "name2", "name3", "name4"],
};

/**
 * Component to display arcs which correspond to incoming and outgoing mobility of a region
 * @function ArcGroup
 * @kind React component
 * @param {Object} props - props
 * @param [props.startCentroid] {Array<number>} - latlng of the starting region
 * @param [props.targetCentroids] {Array} - array of target regions Array<[[lat,lng], outgoing, incoming]>
 * @param [props.targetNames] {Array<string>} - array of corresponding names of regions
 * @param [props.popup] {boolean} - show info on hover
 * @param [props.colors] {Array<string>} - [outgoingColor, incomingColor]
 * @param [props.id] {?(string|number)} - id of a chosen region
 * @param [props.showIn] {boolean} - show incoming mobility
 * @param [props.showOut] {boolean} - show outgoing mobility
 */
function ArcGroup(props){
    const arcs = [];
    const inLatLng = props.startCentroid;
    const popup = props.popup===undefined? true : props.popup;
    // const circleTooltip = false;
    const showIn = props.showIn===undefined? true: props.showIn;
    const showOut = props.showOut===undefined? true: props.showOut;
    let colors = props.colors===undefined? ["#ffa366", "#6691ff"]: props.colors;
    for (let i=0; i<props.targetCentroids.length; i++) {
        const [outLatLng, outPeople, inPeople] = props.targetCentroids[i];
        const targetName = props.targetNames[i];

        const outWidth = binsToWidth(outPeople, props.bins);
        const inWidth = binsToWidth(inPeople, props.bins);
        arcs.push(showOut && outWidth>0?
            <CurveArcTouch
                positions={[inLatLng, outLatLng]}
                  color={tinycolor(colors[0]).darken(outWidth*3).setAlpha(.4).toRgbString()}
                  popup={popup?`<span style="color: ${colors[0]};" class="any-text">${props.startName} ➡️ ${targetName}: ${lessOne(outPeople)} </span>`:undefined}
                  curvness={4}
                  weight={outWidth}
                  key={i+1}
                  animate={{duration: 600}}
            />: null);
        arcs.push(showIn && inWidth>0?
            <CurveArcTouch  positions={[outLatLng, inLatLng]}
                  color={tinycolor(colors[1]).darken(inWidth*3).setAlpha(.6).toRgbString()}
                  popup={popup?`<span style="color: ${colors[1]}; " class="any-text">${props.startName} ⬅️ ${targetName}: ${lessOne(inPeople)}</span>`:undefined}
                  curvness={4}
                  weight={inWidth}
                  key={props.targetCentroids.length + i+1}
                  animate={{duration: 600}}
            />: null);
        arcs.push(
        //     circleTooltip?
        //         showOut||showIn?
        //             <CircleWithTooltip center={outLatLng}
        //                                color={'white'}
        //                                fillOpacity={1.0}
        //                                weight={0}
        //                                radius={binsToWidth(Math.max(inPeople, outPeople), props.bins)/2}
        //                                tooltip={`<span style="color: green; ">in</span>: ${inPeople} <br/> 
        //                             <span style="color: red; ">out</span>: ${outPeople}`}
        //                                key={props.targetCentroids.length*2 + i+1}
        //             />:null :
                showOut||showIn?
                    <CircleMarker
                        center={outLatLng}
                        color={'white'}
                        fillOpacity={1.0}
                        weight={0}
                        radius={binsToWidth(Math.max(inPeople, outPeople), props.bins)/2}
                        key={props.targetCentroids.length*2 + i+1}
                    />
        :null
        );
    }
    arcs.push(
        // popup?
    //     <CircleWithTooltip center={inLatLng}
    //                        radius={3}
    //                        color={"white"}
    //                        fillOpacity={1.0}
    //                        fillColor={'white'}

    //                        key = {0}
    //                        className={'fade-in'}
    //                        tooltip={`${props.outSum}`}
    //     />:
        <CircleMarker center={inLatLng}
                      radius={3}
                      color={"white"}
                      fillOpacity={1.0}
                      fillColor={'white'}
                      key = {0}
                      className={'fade-in'}
        />
    );

    return(
        <FeatureGroup key={hash(props)}>
            {arcs}
        </FeatureGroup>);
}

ArcGroup.propTypes = {
    startCentroid: PropTypes.arrayOf(PropTypes.number).isRequired,
    startName: PropTypes.string,
    targetCentroids:
        PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.oneOfType([
                    PropTypes.arrayOf(PropTypes.number), PropTypes.number
                ])
            )
        ).isRequired,
    targetNames: PropTypes.arrayOf(PropTypes.string),
    popup: PropTypes.bool,
    colors: PropTypes.arrayOf(PropTypes.string),
    showIn: PropTypes.bool,
    showOut: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bins: PropTypes.arrayOf(PropTypes.number),
};
ArcGroup.defaultProps={...example,
    popup: false,
    colors:["#ffa366", "#6691ff"],
    showIn: true,
    showOut:true,
    bins: [0, 5, 10, 50, 500, 5000, 50000, 100000]
}
export default ArcGroup;