import React from "react";
import {FeatureGroup} from "react-leaflet";
import CurveArc from "./CurveArcNew";


export default function CurveArcTouch(props) {
    return (
        <FeatureGroup>
            <CurveArc {...props} popup={undefined}/>
            <CurveArc {...props} weight={props.weight*1.5+3} animate={{}} color={"rgba(255,255,255,0)"}/>
        </FeatureGroup>
    );
}


