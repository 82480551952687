import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { strings } from "../localization/strings";


export default function ArcController(props) {
    return (
        <React.Fragment>
            <FormControlLabel
                control ={<Switch
                    size="small"
                    name="out"
                    checked={props.selectedArcs.out}
                    onChange={props.handleArcsSwitchChange}
                    color="primary"
                />}
                label={strings.outgoingMobility}
                disabled={props.disabled}
            /><br/>
            <FormControlLabel
                control ={<Switch
                    size="small"
                    name="in"
                    checked={props.selectedArcs.in}
                    onChange={props.handleArcsSwitchChange}
                    color="primary"
                />}
                label={strings.incomingMobility}
                disabled={props.disabled}
            /><br/>
        </React.Fragment>
    );
}

ArcController.propTypes = {
    disabled: PropTypes.bool,
    selectedArcs: PropTypes.exact({
        in: PropTypes.bool,
        out: PropTypes.bool,
    }),
    handleArcsSwitchChange: PropTypes.func,
};