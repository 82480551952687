import React, {useState} from "react";
import {token} from "../token";
import {toDay, urlMaxDayHour, urlMinDayHour} from "../utils/dateStringUtils";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import DateSelectionController from "../controller/DateSelectionController";
import {strings} from "../localization/strings";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {Grid} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";


const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: "md",//"sm"
        marginBottom: theme.spacing(2),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        padding:20,
        margin: 10,
    },
    button: {
        marin: theme.spacing(1),
        backgroundColor: "primary",
        marginTop: theme.spacing(2),
    }

}));


/**
 * Component to display download tools
 * @function DownloadDialog
 * @kind React component
 * @param {Object} props
 * @param [props.style] {Object} - style object
 */
export default function DownloadDialog(props) {
    const dateBoundaries = {
        od: [new Date("2017-01-01T00:00:00"), new Date("2018-03-01T23:00:00") ],
        countyBus: [new Date("2017-01-01T00:00:00"), new Date("2019-12-31T23:00:00")],
        bus: [new Date("2019-07-01T00:00:00"), new Date("2019-12-31T23:00:00")],
        bike: [new Date("2019-07-01T00:00:00"), new Date("2019-12-31T23:00:00")]
    };

    const downloadNames = {
        od: "TartuCountyMPD",
        countyBus: "TartuCountyBus",
        bike: "TartuCityBike",
        bus: "TartuCityBus",
    }

    const shapeFiles = {
        od: "https://mara.ut.ee/shapefile/TartuCounty_Kant_TerritorialCommunity.gpkg",
        countyBus: "https://mara.ut.ee/shapefile/TartuCounty_Kant_TerritorialCommunity.gpkg",
        bike: "https://mara.ut.ee/shapefile/TartuCity_Asum_Neighbourhood.gpkg",
        bus: "https://mara.ut.ee/shapefile/TartuCity_Asum_Neighbourhood.gpkg",
    }

    const prepareFetch = (dataType, date, resolution)=>{
        resolution = resolution==="month"? "day": resolution;
        // resolution one of ["day", "hour"]
        // date array of [Date, Date]
        const api = "https://mara.ut.ee/api/";
        const apiTables = {
            od: "od_matrix",
            countyBus: "county_results_agg_",
            bus: "city_results_agg_",
            bike:"bike_results_agg_",
        };
        return api + apiTables[dataType] +
            (dataType==="od"?"":resolution) +
            "?" +
            `${resolution==="day"?"date":"hour"}=gte.${toDay(date[0])}`+
            (resolution==="day"?"":urlMinDayHour)+
            `&${resolution==="day"?"date":"hour"}=lte.${toDay(date[1])}`+
            (resolution==="day"?"":urlMaxDayHour);
    };
    const classes = useStyles();
    const [state, setState] = React.useState({
        dataType: 'od',
        dataResolution: 'month',
    });
    const [selectedDate, setSelectedDate] = useState(dateBoundaries["od"]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [downloadShape, setDownloadShape] = useState(false);

    const calendarVariant = {
        month: "year",
        day: "month",
        hour: "day",
    };
    const handleOpenButton = ()=>{
        setOpen(true);
    }

    const handleChangeType = (event) => {
        // const name = event.target.name;
        if (event.target.value==="od") {
            setState({
                "dataResolution": "month",
                "dataType": event.target.value,
            });
        } else {
            setState({
                //...state,
                "dataType": event.target.value,
                "dataResolution": state.dataResolution==="month"? "day": state.dataResolution
            });
        }
        setSelectedDate(dateBoundaries[event.target.value]);
    };

    const handleChangeResolution = (event) => {
        setState({
            ...state,
            dataResolution: event.target.value,
        })
    }

    const handleDateChange = (position)=>(date)=>{
        if (isNaN(date.getTime())){
            return;
        }
        if (position === 0) {
            setSelectedDate([date, selectedDate[1]]);
        } else if (position === 1) {
            setSelectedDate([selectedDate[0], date]);
        }
    }

    const handleClose = ()=>{
        setOpen(false);
    }

    const handleClick = () => {
        setLoading(true);
        setError(false);
        const request = prepareFetch(state.dataType, selectedDate, state.dataResolution);
        console.log(request);
        fetch(request, {headers:{'Accept': 'text/csv', 'Authorization': 'Bearer ' + token}})
            .then(
                response => {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `${downloadNames[state.dataType]}_data_${state.dataResolution}`+
                            `.csv`;
                        a.click();
                        a.remove();
                        setLoading(false);
                        if (downloadShape) {
                            setTimeout(()=>{
                                let url = shapeFiles[state.dataType];
                                let a1 = document.createElement('a',);
                                a1.href = url;
                                a1.click();
                            }, 300);
                        }
                    });},
                (error)=>{
                    console.log(error);
                    setError(true);
                    setLoading(false);
                }
            )
            .then((v)=>{

            });

    };
    return (
        <div style={props.style}>
            <Button
                variant="outlined"
                onClick={handleOpenButton}>
                {strings.download}
            </Button>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                maxWidth={"md"}
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            {strings.download}
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            <div className={classes.root}>
                <Grid container direction="column">
                            <FormControl
                                className={classes.formControl}
                                fullWidth={false}
                            >
                                <InputLabel
                                >{strings.dataType}</InputLabel>
                                <Select
                                    value={state.dataType}
                                    onChange={handleChangeType}
                                >
                                    <MenuItem value={"od"}>{strings.mobilePositioningDataInTartuCounty}</MenuItem>
                                    <MenuItem value={"countyBus"}>{strings.tartuCountyBusData}</MenuItem>
                                    <MenuItem value={"bus"}>{strings.tartuCityBusData}</MenuItem>
                                    <MenuItem value={"bike"}>{strings.tartuCityBikeShareData}</MenuItem>
                                </Select>
                            </FormControl>
                <br/>
                            <FormControl
                                className={classes.formControl}
                                fullWidth={false}>
                                <InputLabel
                                >{strings.dataResolution}</InputLabel>
                                <Select
                                    fullWidth={true}
                                    value={state.dataResolution}
                                    onChange={handleChangeResolution}
                                >
                                    {state.dataType==="od"?<MenuItem value={"month"}>{strings.month}</MenuItem>
                                        :
                                        null
                                    }
                                    {state.dataType!=="od"?<MenuItem value={"day"}>{strings.day}</MenuItem>
                                        :
                                        null
                                    }
                                    {state.dataType!=="od"?<MenuItem value={"hour"}>{strings.hour}</MenuItem>
                                        :
                                        null
                                    }
                                </Select>
                            </FormControl>
                            <DateSelectionController
                                className={classes.formControl}
                                variant={calendarVariant[state.dataResolution]}
                                selectedDate={selectedDate}
                                handleDateChange={handleDateChange}
                                enableRange={true}
                                dateBoundaries={dateBoundaries[state.dataType]}
                                boundSecondDate={false}
                            />
                <br/>
                            <FormControlLabel
                                control ={<Switch
                                    name="shapeFile"
                                    checked={downloadShape}
                                    onChange={(e) => {setDownloadShape(e.target.checked)}}
                                    color="primary"
                                />}
                                label={strings.downloadShape}
                            />
                <br/>
                            <Button
                                className={classes.button}
                                onClick={handleClick}>{strings.download}</Button>

                <Fade in={loading}
                      unmountOnExit
                >
                    <React.Fragment>
                        <LinearProgress/>
                        <Typography variant="caption">
                            {strings.loading}
                        </Typography>
                        <br/>
                    </React.Fragment>
                </Fade>
                {error?<Typography variant="caption">
                    {strings.error}
                </Typography>:null}
                </Grid>
            </div>
            </Dialog>
        </div>
    );
}