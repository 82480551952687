import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import DataSelectorController from "./DataSelectorController";
// import ShowMobilityController from "./ShowMobilityController";
import DateSelectionController from "./DateSelectionController";
import DayMonthRangeController from "./DayMonthRangeController";
import SliderController from "./SliderController";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import ArcController from "./ArcController";
import {strings} from "../localization/strings";


const useStyles = makeStyles({
    root: {
        width: "330px",
        overflow: "auto",
        left: 10,
        top: 10,
        backgroundColor: "rgba(255,255,255,0.7)",
        zIndex: 510,
        borderRadius: 20,
        margin: 0,
        padding: 30,
        position: "absolute",
    },
    button: {
        marginTop: 10,
        marginRight: 10,
    },
});

/**
 * Higher order component that handles user interface
 * @function Controller
 * @kind React component
 * @param  props {Object} - props
 * @param [props.disabled] {boolean} - makes UI elements disabled
 * @param [props.dataState] {{od: boolean, countyBus: boolean,bike: boolean, bus:boolean}} - selected data types
 * @param [props.destination] {boolean} - value to chose incoming or outgoing mobility
 * @param [props.noMobilityData] {boolean} - value to hide the choropleth map
 * @param [props.dateVariant] {("day"| "month"| "year")} - temporal resolution for date display
 * @param [props.selectedDate] {Array<Date>} - selected date range
 * @param [props.dateBoundaries] {Array<Date>} - current date boundaries for selected data types
 * @param [props.dayMonthRangeState] {{enableYear: boolean, enableMonth: boolean, enableDay: boolean}} - time resolution state
 * @param [props.sliderValue] {number} - slider value (used for data filtering)
 * @param [props.animate] {boolean} - animate slider
 * @param [props.loading] {boolean} - true if loading
 * @param [props.error] {boolean} - true if error while loading happened
 * @param [props.preparing] {boolean} - true if calculating values to update components
 * @param [props.selectedArcs] {{in: boolean, out: boolean}} - which mobility to display {in: boolean, out: boolean}
 * @param [props.handleDataSwitchChange] {callback} - (event)=>{} data type picking
 * @param [props.handleSwitchMobilityChange] {callback} - (event)=>{} incoming or outgoing mobility picking
 * @param [props.handleDateChange] {callback} - (position)=>(date)=>{} date range selection
 * @param [props.handleLeftArrow] {callback} - ()=>{} LeftButton click
 * @param [props.handleRightArrow] {callback} - ()=>{} RightButton click
 * @param [props.handleDateMonthRangeSwitchChange] {callback} - (event)=>{} temporal resolution picking
 * @param [props.sliderHandleChange] {callback} - (event, value)=>{} slider value picking
 * @param [props.handleButtonAnimate] {callback} - ()=>{} PlayStopButton click
 * @param [props.handleArcsSwitchChange] {callback} - (event)=>{} selection of arcs to display
 * @param [props.handleResetArcsClick] {callback} - ()=>{} reset arcs click
 * @param [props.handleClearMapClick] {callback} - ()=>{} clear map click
 */
export default function Controller(props) {
    const classes = useStyles(props);
    return (
        <div className={classes.root} id={"controller"}>
            <Typography variant="h5" gutterBottom>
                {strings.populationMobilityMonitor}
            </Typography>
            <Typography variant="caption">
                {strings.selectDataToDisplay}
            </Typography>
            <br/>
            <DataSelectorController
                state={props.dataState}
                handleSwitchChange={props.handleDataSwitchChange}
                disabled={props.disabled}
            />
            <br/>
            <Typography variant="caption" gutterBottom>
                {strings.selectMobilityType}
            </Typography><br/>
            <ArcController
                handleArcsSwitchChange={props.handleArcsSwitchChange}
                selectedArcs={props.selectedArcs}
                disabled={props.disabled}
            />
            <br/>
            <Typography variant="caption">
                {strings.selectTemporalScale}
            </Typography><br/>
            <DayMonthRangeController
                state={props.dayMonthRangeState}
                handleDateMonthRangeSwitchChange={props.handleDateMonthRangeSwitchChange}
                disableDay={props.dataState.od}
                disabled={props.disabled}
            />
            <DateSelectionController
                variant={props.dateVariant}
                enableRange={props.dayMonthRangeState.enableYear || props.dayMonthRangeState.enableMonth}
                handleDateChange={props.handleDateChange}
                selectedDate={props.selectedDate}
                dateBoundaries={props.dateBoundaries}
                enableArrows={props.dateVariant==="day"}
                handleLeftArrow={props.handleLeftArrow}
                handleRightArrow={props.handleRightArrow}
                disabled={props.disabled}
            />

            {!props.dayMonthRangeState.enableDay ? null:
                <SliderController mode={props.dayMonthRangeState.enableDay?"day":"month"}
                                  value={props.sliderValue}
                                  handleChange={props.sliderHandleChange}
                                  date={props.selectedDate[0]}
                                  handleButtonAnimate={props.handleButtonAnimate}
                                  animate={props.animate}
                                  disabled={props.disabled}
                />}
            <br/>

            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={props.handleResetArcsClick}
            >
                {strings.unselectRegion}
            </Button>
            <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={props.handleClearMapClick}
            >
                {strings.clearMap}
            </Button>
            <br/><br/>
            <Fade in={props.loading}
                  unmountOnExit
            >
                <React.Fragment>
                    <LinearProgress/>
                    <Typography variant="caption">
                        {strings.loading}
                    </Typography>
                    <br/>
                </React.Fragment>
            </Fade>
            <Fade in={props.preparing}
                  unmountOnExit
            >
                <React.Fragment>
                    <LinearProgress/>
                    <Typography variant="caption">
                        {strings.preparing}
                    </Typography>
                    <br/>
                </React.Fragment>
            </Fade>
            <Fade in={props.error}
                  unmountOnExit
            >
                <Typography variant="caption">
                    {strings.error}
                </Typography>
            </Fade>
        </div>
    );
}

Controller.propTypes = {
    disabled: PropTypes.bool,
    dataState: PropTypes.exact({
        od: PropTypes.bool,
        countyBus: PropTypes.bool,
        bike: PropTypes.bool,
        bus: PropTypes.bool,
    }),
    handleDataSwitchChange: PropTypes.func,

    destination: PropTypes.bool,
    noMobilityData: PropTypes.bool,
    handleSwitchMobilityChange: PropTypes.func,

    dateVariant: PropTypes.oneOf(["day", "month", "year"]),
    handleDateChange: PropTypes.func,
    selectedDate: PropTypes.arrayOf(Date),
    dateBoundaries: PropTypes.arrayOf(Date),
    handleLeftArrow: PropTypes.func,
    handleRightArrow: PropTypes.func,

    dayMonthRangeState: PropTypes.exact({
        enableYear: PropTypes.bool,
        enableMonth: PropTypes.bool,
        enableDay: PropTypes.bool,
    }),
    handleDateMonthRangeSwitchChange: PropTypes.func,

    sliderValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
    sliderHandleChange: PropTypes.func,
    handleButtonAnimate: PropTypes.func,
    animate: PropTypes.bool,


    loading: PropTypes.bool,
    error: PropTypes.bool,
    preparing: PropTypes.bool,
    selectedArcs:  PropTypes.exact({
        in: PropTypes.bool,
        out: PropTypes.bool,
    }),
    handleArcsSwitchChange: PropTypes.func,
    handleResetArcsClick: PropTypes.func,
    handleClearMapClick: PropTypes.func,
};

